import React from 'react';
import calendar from './../../../assets/img/brand/pzu/calendar.png';
import info from './../../../assets/img/brand/pzu/info.png';
import arrowRight from './../../../assets/img/brand/pzu/arrowRight.png';
import exclamationMark from './../../../assets/img/brand/pzu/exclamationMark.png';

const ZasadyPrzystepowania = (props) => {
  return (
    <React.Fragment>
      <h3 className="page-header firstColor">
        Jak przystąpić do ubezpieczenia zdrowotnego
      </h3>
      <div className="secondColor">
        <p>
          Każdy pracownik TVP ma możliwość skorzystania z opieki medycznej
          finansowanej przez pracodawcę.
        </p>
        <p>
          Aby przystąpić do ubezpieczenia zarejestruj się i{' '}
          <b>wypełnij formularz online dla siebie i swoich najbliższych.</b>
        </p>
        <div className="container mt-4">
          <div className="row">
            <div className="col-sm-1 d-flex align-items-center">
              <img src={arrowRight} alt="calendar" />
            </div>
            <div className="col-sm">
              Wydrukuj i <b>podpisz dokumenty</b> wraz ze{' '}
              <b>wszystkimi zgłoszonymi członkami rodziny</b> (w przypadku
              niepełnoletniego dziecka deklarację podpisuje opiekun prawny).
            </div>
          </div>

          <div className="row">
            <div className="col-sm-1 d-flex align-items-center"></div>
            <div className="col-sm pl-0">
              <ul className="listArrowRight">
                <li className="mt-4">
                  <b>
                    Oryginał wniosku o przystąpienie do ubezpieczenia
                    zdrowotnego ze zgodą na potrącenia dla TVP S.A.
                  </b>{' '}
                  prześlij za pośrednictwem sekretariatów jednostek
                  organizacyjnych lub przy użyciu wewnętrznej poczty
                  korporacyjnej do BZKL.
                </li>
                <li className="mt-4">
                  <b>Deklarację</b> przystąpienia do ubezpieczenia dla siebie i
                  członków rodziny{' '}
                  <b>prześlij w formie skanu lub zdjęcia na adres mailowy:</b>{' '}
                  <a href="mailto:deklaracje@uspartner.pl">
                    <b className="firstColor">deklaracje@uspartner.pl</b>
                  </a>{' '}
                  lub w oryginale listownie na adres: Unique Service Partner sp.
                  z o.o., ul. Obrzeżna 7, 02-691 Warszawa.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container mt-3">
          <div className="row">
            <div className="col-sm-1 d-flex align-items-center">
              <img src={calendar} alt="calendar" />
            </div>
            <div className="col-sm">
              Aby zostać objętym ubezpieczeniem zdrowotnym od{' '}
              <b>1 dnia miesiąca</b>
              {} należy do <b>10 dnia poprzedniego</b> miesiąca wypełnić
              formularz online i przekazać podpisane dokumenty: wniosek oraz
              deklarację na wyżej wskazane adresy. Jeśli wypełnisz formularz do{' '}
              <b>10 listopada</b> opieka medyczna rozpocznie się od{' '}
              <b>1 grudnia</b>, jeśli wypełnisz formularz pomiędzy 11 listopada
              a 10 grudnia opieka medyczna rozpocznie się <b>1 stycznia.</b>
            </div>
          </div>
        </div>
        <br />
        <span>
          Możesz również objąć ubezpieczeniem członków swojej rodziny,
          wybierając odpowiedni pakiet:
        </span>
        <ul className="mt-3">
          <li>
            Pakiet Partnerski – dla pracownika i jednego członka rodziny
            (małżonka/partnera życiowego albo dziecka*)
          </li>
          <li>
            Pakiet Rodzinny – dla pracownika i dwóch lub więcej członków rodziny
            (małżonka/partnera życiowego i/lub dzieci bez względu na ich
            liczbę*),
            <br />
            <small>
              *Dziecko własne lub przysposobione, pozostające na utrzymaniu
              pracownika TVP, do ukończenia 26 roku życia.
            </small>
          </li>
        </ul>

        <div className="container mt-4">
          <div className="row">
            <div className="col-sm-1 text-right">
              <img src={exclamationMark} alt="calendar" />
            </div>
            <div className="col-sm">
              <b>Nowa propozycja dostępna w ramach ubezpieczenia TUW PZUW</b>
              <br />
              Masz możliwość rozszerzyć opiekę medyczną również na:
            </div>
          </div>
          <div className="row">
            <div className="col-sm-1 d-flex align-items-center"></div>
            <div className="col-sm">
              <ul className="mt-3">
                <li>
                  Dorosłe dziecko – dla dorosłego dziecka pracownika, które
                  ukończyło 26 r.ż.
                </li>
                <li>
                  Seniora – dla rodziców i teściów pracowników TVP, którzy nie
                  ukończyli 80 r.ż
                </li>
              </ul>
            </div>
          </div>
        </div>

        <span className="mt-4">
          Deklarację przystąpienia do ubezpieczenia dla dorosłego dziecka i
          seniora złóż również poprzez formularz on-line wchodząc w odpowiednią
          sekcję „Senior” lub „Dorosłe Dziecko”. Znajdziesz tam zasady płatności
          za pakiety oraz terminy składania deklaracji.
        </span>
        <div className="container mt-4">
          <div className="row">
            <div className="col-sm-1 text-right">
              <img src={info} alt="calendar" />
            </div>
            <div className="col-sm">
              <b>Pamiętaj:</b> aby zgłosić do ubezpieczenia członków rodziny w
              tym seniora lub dorosłe dziecko musisz mieć aktywny pakiet opieki
              medycznej.
            </div>
          </div>
        </div>
        <br />
        <br />
        <span>
          <b className="firstColor">
            Możesz dokonać zmiany pakietu lub zakresu ubezpieczenia wypełniając
            nową deklarację przystąpienia
          </b>
        </span>
        <ul>
          <li>
            zmiana pakietu z indywidualnego na pakiet partnerski lub rodzinny
            jest możliwa w <b>dowolnym momencie</b> z datą obowiązywania od 1
            dnia kolejnego miesiąca,
          </li>
          <li>
            zmiana pakietu z rodzinnego na partnerski lub indywidualny, bądź z
            partnerskiego na indywidualny jest możliwa w dowolnym momencie z
            datą obowiązywania od 1 dnia kolejnego miesiąca,
          </li>
          <li>
            zmiana zakresu z <b>„niższego na wyższy”</b> (tj. z zakresu
            podstawowego na zakres rozszerzony lub zakres VIP, bądź z
            rozszerzonego na VIP) jest możliwa w dowolnym momencie z datą
            obowiązywania od 1 dnia kolejnego miesiąca,
          </li>
          <li>
            zmiana zakresu z „wyższego na niższy” (tj. z zakresu VIP na zakres
            rozszerzony lub zakres podstawowy, bądź z rozszerzonego na
            podstawowy jest możliwa <b>2 razy w roku</b> w określonych
            terminach, tj.
            <br />
            <br />
            <ul>
              <li>
                Dla deklaracji złożonych w okresie od 11 października do 10
                listopada, ze skutkiem od 1 grudnia.
              </li>
              <li>
                Dla deklaracji złożonych w okresie od 11 kwietnia do 10 maja, ze
                skutkiem od 1 czerwca.
              </li>
            </ul>
            <br />
          </li>
        </ul>
        <b className="firstColor">Rezygnacja</b>
        <br />
        <span>
          <b>
            Rezygnacja z ubezpieczenia opieki medycznej i ponowne przystąpienie
          </b>{' '}
          do niej jest możliwe <b>2 razy w roku</b> w określonych terminach:
          <ul className="mt-3">
            <li>
              Dla deklaracji złożonych w okresie od 11 października do 10
              listopada, ze skutkiem od 1 grudnia.
            </li>
            <li>
              Dla deklaracji złożonych w okresie od 11 kwietnia do 10 maja, ze
              skutkiem od 1 czerwca.
            </li>
          </ul>
          <p>
            W celu rezygnacji z ubezpieczenia, po zalogowaniu się na swoje
            konto, należy w sekcji <b>Moje wnioski</b>, zaznaczyć w aktywnym
            wniosku opcję <b>Zrezygnuj</b>.<br /> Rezygnacja jest możliwa w
            wyżej wskazanych terminach.
          </p>
          <br />
          Deklarację rezygnacji dostarczone do 10 dnia miesiąca skutkują
          zakończeniem ochrony ostatnim dniem miesiąca.
        </span>
      </div>
    </React.Fragment>
  );
};

export default ZasadyPrzystepowania;
