export const dates = {
  convert: function (d) {
    // Converts the date in d to a date-object. The input can be:
    //   a date object: returned without modification
    //  an array      : Interpreted as [year,month,day]. NOTE: month is 0-11.
    //   a number     : Interpreted as number of milliseconds
    //                  since 1 Jan 1970 (a timestamp)
    //   a string     : Any format supported by the javascript engine, like
    //                  "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
    //  an object     : Interpreted as an object with year, month and date
    //                  attributes.  **NOTE** month is 0-11.
    return d.constructor === Date
      ? d
      : d.constructor === Array
      ? new Date(d[0], d[1], d[2])
      : d.constructor === Number
      ? new Date(d)
      : d.constructor === String
      ? new Date(d)
      : typeof d === 'object'
      ? new Date(d.year, d.month, d.date)
      : NaN;
  },
  compare: function (a, b) {
    // Compare two dates (could be of any type supported by the convert
    // function above) and returns:
    //  -1 : if a < b
    //   0 : if a = b
    //   1 : if a > b
    // NaN : if a or b is an illegal date
    // NOTE: The code inside isFinite does an assignment (=).
    return isFinite((a = this.convert(a).valueOf())) &&
      isFinite((b = this.convert(b).valueOf()))
      ? (a > b) - (a < b)
      : NaN;
  },
  inRange: function (d, start, end) {
    // Checks if date in d is between dates in start and end.
    // Returns a boolean or NaN:
    //    true  : if d is between start and end (inclusive)
    //    false : if d is before start or after end
    //    NaN   : if one or more of the dates is illegal.
    // NOTE: The code inside isFinite does an assignment (=).
    return isFinite((d = this.convert(d).valueOf())) &&
      isFinite((start = this.convert(start).valueOf())) &&
      isFinite((end = this.convert(end).valueOf()))
      ? start <= d && d <= end
      : NaN;
  }
};

export const min18 = (value) => {
  if (value === '' || !value) return undefined;

  let minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 18);

  let comparison = dates.compare(value, minDate);

  console.log(comparison);

  switch (comparison) {
    case 1:
      return 'Wymagany minimalny wiek: 18 lat.';
    case NaN:
      return 'Zła data';
    case 0:
    case -1:
    default:
      return undefined;
  }
};

export const min26 = (value) => {
  if (value === '' || !value) return undefined;

  let minDate = new Date();
  // minDate.setFullYear(minDate.getFullYear() - 26);
  // minDate.setMonth(10, 30);

  if (minDate.getDate() <= 20) {
    minDate.setMonth(minDate.getMonth() + 1, 1);
  } else {
    minDate.setMonth(minDate.getMonth() + 2, 1);
  }
  minDate.setFullYear(minDate.getFullYear() - 26);

  let comparison = dates.compare(value, minDate);

  console.log(comparison);

  switch (comparison) {
    case 1:
      return 'Wymagany minimalny wiek: 26 lat w dniu początku ochrony.';
    case NaN:
      return 'Zła data';
    case 0:
    case -1:
    default:
      return undefined;
  }
};

export const min16 = (value) => {
  if (value === '' || !value) return undefined;

  let minDate = new Date();
  if (minDate.getDate() <= 20) {
    minDate.setMonth(minDate.getMonth() + 1, 1);
  } else {
    minDate.setMonth(minDate.getMonth() + 2, 1);
  }
  minDate.setFullYear(minDate.getFullYear() - 16);

  let comparison = dates.compare(value, minDate);

  console.log(comparison);

  switch (comparison) {
    case 1:
      return 'Wymagany minimalny wiek: 16 lat w dniu początku ochrony.';
    case NaN:
      return 'Zła data';
    case 0:
    case -1:
    default:
      return undefined;
  }
};

export const max25 = (value) => {
  if (value === '' || !value) return undefined;

  let maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 26);

  let comparison = dates.compare(value, maxDate);

  switch (comparison) {
    case NaN:
      return 'Zła data';
    case 0:
    case -1:
      return 'Brak możliwości dodania dziecka którego wiek przekroczył 26 lat, możesz je dodać jako partnera.';
    case 1:
    default:
      return undefined;
  }
};
export const max80 = (value) => {
  if (value === '' || !value) return undefined;

  let maxDate = new Date();
  // maxDate.setFullYear(maxDate.getFullYear() - 80);
  // maxDate.setMonth(10, 30);

  if (maxDate.getDate() <= 20) {
    maxDate.setMonth(maxDate.getMonth() + 1, 1);
  } else {
    maxDate.setMonth(maxDate.getMonth() + 2, 1);
  }
  maxDate.setFullYear(maxDate.getFullYear() - 80);

  let comparison = dates.compare(value, maxDate);

  switch (comparison) {
    case NaN:
      return 'Zła data';
    case 0:
    case -1:
      return 'Brak możliwości dodania seniora którego wiek przekroczył 80 lat.';
    case 1:
    default:
      return undefined;
  }
};

export const max80Tuwpzuw = (value) => {
  if (value === '' || !value) return undefined;

  let maxDate = new Date();
  // maxDate.setFullYear(maxDate.getFullYear() - 80);
  // maxDate.setMonth(10, 30);

  if (maxDate.getDate() <= 10) {
    maxDate.setMonth(maxDate.getMonth() + 1, 1);
  } else {
    maxDate.setMonth(maxDate.getMonth() + 2, 1);
  }
  maxDate.setFullYear(maxDate.getFullYear() - 80);

  let comparison = dates.compare(value, maxDate);

  switch (comparison) {
    case NaN:
      return 'Zła data';
    case 0:
    case -1:
      return 'Brak możliwości dodania osoby, która ma więcej niż 80 lat.';
    case 1:
    default:
      return undefined;
  }
};

export const walidatorDziecko = (value) => {
  if (value === '' || !value) return undefined;

  // let maxDateSenior = new Date();
  // maxDateSenior.setFullYear(maxDate.getFullYear() - 67);
  // maxDateSenior.setMonth(10, 30);
  let maxDate = new Date();
  if (maxDate.getDate() <= 10) {
    maxDate.setMonth(maxDate.getMonth() + 1, 1);
  } else {
    maxDate.setMonth(maxDate.getMonth() + 2, 1);
  }
  maxDate.setFullYear(maxDate.getFullYear() - 26);

  let comparison = dates.compare(value, maxDate);
  // let comparison2 = dates.compare(value, maxDateSenior);

  switch (comparison) {
    case NaN:
      return 'Zła data';
    case 0:
    case -1:
      // if (comparison2 === -1)
      //   return 'Brak możliwości dodania dziecka którego wiek przekroczył 26 lat.';
      return 'Brak możliwości dodania dziecka którego wiek przekroczył 26 lat w momencie początku ochrony. Prosimy wypełnić osobną deklarację Dorosłe Dziecko.';
    case 1:
    default:
      return undefined;
  }
};
export const walidatorDziecko2 = (value) => {
  if (value === '' || !value) return undefined;

  // let maxDateSenior = new Date();
  // maxDateSenior.setFullYear(maxDate.getFullYear() - 67);
  // maxDateSenior.setMonth(10, 30);
  let maxDate = new Date();
  if (maxDate.getDate() <= 20) {
    maxDate.setMonth(maxDate.getMonth() + 1, 1);
  } else {
    maxDate.setMonth(maxDate.getMonth() + 2, 1);
  }
  maxDate.setFullYear(maxDate.getFullYear() - 26);

  let comparison = dates.compare(value, maxDate);
  // let comparison2 = dates.compare(value, maxDateSenior);

  switch (comparison) {
    case NaN:
      return 'Zła data';
    case 0:
    case -1:
      // if (comparison2 === -1)
      //   return 'Brak możliwości dodania dziecka którego wiek przekroczył 26 lat.';
      return 'Brak możliwości dodania dziecka którego wiek przekroczył 26 lat w momencie początku ochrony. Prosimy wypełnić osobną deklarację Dorosłe Dziecko.';
    case 1:
    default:
      return undefined;
  }
};

export const walidatorWspolpracownikPlusPartner = (value) => {
  if (value === '' || !value) return undefined;

  let maxDate = new Date();
  // let maxYear = maxDate.getFullYear() - 67;
  // maxDate.setFullYear(maxYear);
  // maxDate.setMonth(10, 30);

  if (maxDate.getDate() <= 20) {
    maxDate.setMonth(maxDate.getMonth() + 1, 1);
  } else {
    maxDate.setMonth(maxDate.getMonth() + 2, 1);
  }
  maxDate.setFullYear(maxDate.getFullYear() - 67);

  let comparison = dates.compare(value, maxDate);

  switch (comparison) {
    case NaN:
      return 'Zła data';
    case 0:
    case -1:
      return 'Brak możliwości objęcia świadczeniem osób powyżej 67 roku życia. Prosimy wypełnić osobną deklarację Senior.';
    case 1:
    default:
      return undefined;
  }
};

export const walidatorDorosleDziecko = (value) => {
  const validate26 = min26(value);
  const validate67 = walidatorWspolpracownikPlusPartner(value);

  return validate26 || validate67 || undefined;
};

export const walidatorSenior = (value) => {
  const validate16 = min16(value);
  const validate80 = max80(value);

  return validate16 || validate80 || undefined;
};

export const walidatorSeniorTuwpzuw = (value) => {
  const validate16 = min16(value);
  const validate80 = max80Tuwpzuw(value);

  return validate16 || validate80 || undefined;
};

export const max67 = (value) => {
  if (value === '' || !value) return undefined;

  let maxDate = new Date();
  let maxYear = maxDate.getFullYear() - 68;
  maxDate.setMonth(0, 1);
  // maxDate.setMonth(maxDate.getMonth() + 1, 1);
  maxDate.setFullYear(maxYear);

  let comparison = dates.compare(value, maxDate);

  switch (comparison) {
    case NaN:
      return 'Zła data';
    case 0:
    case -1:
      return 'Brak możliwości objęcia świadczeniem osób powyżej 67 roku życia.';
    case 1:
    default:
      return undefined;
  }
};

export const min18max67 = (value) => {
  const validate18 = min18(value);
  const validate67 = max67(value);

  return validate18 || validate67 || undefined;
};

export const kodPocztowy = (value) => {
  const isOk = /^[0-9]{2}-[0-9]{3}$/.test(value);

  if (isOk) {
    return undefined;
  } else {
    return 'Kod pocztowy powinien być w formacie 00-000';
  }
};

export const nineDigitPhone = (value) => {
  const isOk = /^\d{9}$/.test(value) || !value || value === '';

  if (isOk) {
    return undefined;
  } else {
    return 'Numer telefonu musi zawierać 9 cyfr.';
  }
};

export const emailOrNull = (value) => {
  const isOk = /^\S+@\S+\.\w+$/.test(value) || !value || value === '';

  if (isOk) {
    return undefined;
  } else {
    return 'Prosimy o wprowadzenie prawidłowego adresu email.';
  }
};

// export const min18max26 = (value) => {
//   if (value === '' || !value) return undefined;

//   let minDate = new Date();
//   minDate.setFullYear(minDate.getFullYear() - 18);
//   let maxDate = new Date();
//   maxDate.setFullYear(maxDate.getFullYear() - 26);

//   let comparison = dates.inRange(value, minDate, maxDate);

//   switch (comparison) {
//     case false:
//       return 'Minimum 18 lat, max 26 lat';
//     case NaN:
//       return 'Zła data';
//     case true:
//     default:
//       return 'eelelel';
//   }
// };
