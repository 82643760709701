import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

const Ogolne = (props) => {
  return (
    <React.Fragment>
      <h5 className="page-header firstColor ">
        <b>Grupowe Ubezpieczenie TUW PZUW Opieka Medyczna</b> to profesjonalna{' '}
        <b>opieka medyczna</b>, w renomowanych placówkach medycznych w całej
        Polsce.
      </h5>
      <div className="secondColor">
        <p className="firstColor">
          <b>W ramach ubezpieczenia medycznego TUW PZUW otrzymujesz:</b>
        </p>

        <ul>
          <li>
            wizyty u wykwalifikowanych specjalistów <b>bez skierowań,</b>
          </li>
          <li>
            dostęp do szerokiego katalogu <b>badań i zabiegów</b>, również na
            podstawie skierowania wystawionego poza siecią PZU Zdrowie,
          </li>
          <li>
            <b>gwarancję krótkiego terminu realizacji</b> wizyty u lekarza POZ
            (internista, lekarz rodzinny) maksymalnie do <b>2 dni roboczych</b>,
            a u <b>specjalistów</b> do <b>5 dni</b> roboczych.
          </li>
          <br />

          <small>
            Terminy dostępności nie obowiązują w przypadku podania przez
            pacjenta preferencji dotyczącej wyboru terminu, placówki medycznej
            lub lekarza.
          </small>
        </ul>

        <p className="firstColor">
          Aby przystąpić do ubezpieczenia wypełnij deklarację online, a
          następnie <b>wydrukuj i podpisz dokument.</b>{' '}
        </p>

        <p>
          Wypełnioną i podpisaną deklarację <b>prześlij</b> w formie skanu na
          adres mailowy {/* <span style={{ color: 'red' }}> */}
          <b>tuwpzuw@uspartner.pl</b>
          {/* </span>{' '} */} bądź listownie na adres Datus sp. z o.o., ul.
          Obrzeżna 7, 02-691 Warszawa.
        </p>

        <p>
          Aby zostać objętym ubezpieczeniem zdrowotnym od <b>1 dnia miesiąca</b>{' '}
          wypełnij deklarację do <b>10 dnia miesiąca</b> poprzedzającego
          rozpoczęcie ochrony ubezpieczeniowej.
        </p>

        <p>
          Składki za ubezpieczenie opłacane są indywidualnie na wskazany w
          deklaracji numer subkonta bankowego. Składka opłacana jest miesięcznie
          i musi zostać zaksięgowana do 15 dnia każdego miesiąca.{' '}
          <b>
            <u>
              Nieterminowe opłacenie składki będzie skutkowało zakończeniem
              ochrony bez możliwości ponownego przystąpienia.
            </u>
          </b>
        </p>
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            marginTop: '3rem',
            marginBottom: '3rem'
          }}>
          <Button tag={Link} to={`/kontynuacja/kup/`} color="primary" size="lg">
            <i className="fa fa-chevron-circle-right" /> Wypełnij deklarację
          </Button>
        </div>
        <p className="firstColor">
          <b>Chcesz umówić się na wizytę lekarską lub badanie:</b>
        </p>

        <ul>
          <li>
            zadzwoń na naszą infolinię (24h/7{' '}
            <b>22 505 15 48; 799 698 698; 801 405 905 </b> (opłata zgodnie z
            taryfą operatora);
          </li>
          <li>
            skorzystaj z Portalu mojePZU na stronie <b>moje.pzu.pl</b> (po
            wcześniejszym założeniu konta);
          </li>
          <li>
            umów się <b>bezpośrednio</b> w placówkach własnych PZU Zdrowie.
          </li>
        </ul>
        <br />
        <p className="mt-3 firstColor">
          <b>Skontaktuj się z nami jeśli potrzebujesz wsparcia:</b>
        </p>
        <p className="firstColor">Przy składaniu deklaracji:</p>
        <p>
          Zadzwoń pod numer 22 417 34 40 - infolinia czynna od poniedziałku do
          piątku w godzinach 8:00 - 16:00.
        </p>
        <br />
        <p className="firstColor">Wsparcie merytoryczne TUW PZUW:</p>
        <p>
          Napisz na adres:{' '}
          <b className="firstColor">
            <u>ubezpieczeniazdrowotne@tuwpzuw.pl</u>
          </b>{' '}
          w tytule wiadomości dopisz nawę byłego pracodawcy
        </p>
        <br />
        <p className="firstColor">Wsparcie przy zakładaniu konta na MojePZU:</p>
        <ul>
          <li>Zadzwoń pod numer 22 566 50 07 (wybierz tonowo 5)</li>
          <li>Napisz wiadomość na adres moje@pzu.pl</li>
        </ul>
        <br />
        <p>
          <b className="firstColor">Dokumenty do pobrania:</b>
        </p>
        <ul>
          <li>
            <a
              href="/public-assets/tuwpzuw/Zakres Komfort -Kontynuacja.pdf"
              target="_blank">
              Zakres ubezpieczenia Komfort Kontynuacja
            </a>
          </li>
          <li>
            <a
              href="https://zdrowie.pzu.pl/lekarze-i-placowki?searchType=facility"
              target="_blank">
              Nasze placówki
            </a>
          </li>
        </ul>
      </div>
      <br />
      <br />
    </React.Fragment>
  );
};

export default Ogolne;
