import React from 'react';
import { Link } from 'react-router-dom';
import mail from './../../../assets/img/brand/pzu/mail.png';
import tablet from './../../../assets/img/brand/pzu/tablet.png';
import post from './../../../assets/img/brand/pzu/post.png';
import person from './../../../assets/img/brand/pzu/person.png';
import mobile from './../../../assets/img/brand/pzu/mobile.png';

const Reklamacja = (props) => {
  return (
    <React.Fragment>
      <h3 className="page-header firstColor mb-4">Reklamacje</h3>
      <p>
        Jeśli masz uwagi do działania ubezpieczenia lub realizacji usług
        medycznych możesz złożyć reklamację w wybrany przez Ciebie sposób:
      </p>
      <div class="container">
        {/* <div class="row mt-3">
          <div class="col-sm-1 d-flex align-items-center justify-content-center">
            <img src={mail} alt="mail" />
          </div>
          <div class="col-sm-11">
            mailowo na adres{' '}
            <b>
              <a href="mailto:reklamacje@tuwpzuw.pl">reklamacje@tuwpzuw.pl</a>
            </b>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-1 d-flex align-items-center justify-content-center">
            <img src={tablet} alt="tablet" />
          </div>
          <div class="col-sm-11">
            telefonicznie pod numer 801 159 159 (opłata zgodna z taryfą
            operatora);
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-1 d-flex align-items-center justify-content-center">
            <img src={post} alt="post" />
          </div>
          <div class="col-sm-11">
            listownie na adres: TUW PZUW: rondo Ignacego Daszyńskiego 4, 00-843
            Warszawa;
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-1 d-flex align-items-center justify-content-center">
            <img src={person} alt="person" />
          </div>
          <div class="col-sm-11">
            bezpośrednio w siedzibie TUW PZUW w formie pisemnej lub ustnej;
          </div>
        </div>
        <div class="row  mt-3">
          <div class="col-sm-1 d-flex align-items-center justify-content-center">
            <img src={www} alt="www" />
          </div>
          <div class="col-sm-11">
            w aplikacji, klikając „Zgłoś reklamację” (po wcześniejszym
            zalogowaniu).
          </div>
        </div> */}
        <br />
        <div class="row mt-3">
          <div class="col-sm-1 d-flex align-items-center justify-content-center">
            <img src={mail} alt="mail" />
          </div>
          <div class="col-sm-11">
            klikając „Zgłoszenie reklamacji”{' '}
            <span className="lightBlueColor">(forma rekomendowana).</span>{' '}
            Wymagane zalogowanie się do systemu{' '}
          </div>
        </div>
      </div>
      <div className="text-center mt-4">
        <Link
          to="/e512472b-b254-4127-9183-4ce840d76b71/78d1a005-2e80-449c-8143-e90e986c738d"
          className="btn btn-lg btn-primary">
          Zgłoszenie reklamacji
        </Link>
      </div>
      <div className="mt-4 lightBlueColor">
        UWAGA: zgłoszenie reklamacji w aplikacji pozwala na bieżący monitoring
        procesu przez brokera WTW Polska{' '}
      </div>
      <br />
      <div className="container">
        {/*  */}{' '}
        <div class="row mt-3">
          <div class="col-sm-1 d-flex align-items-center justify-content-center">
            <img src={mobile} alt="mail" />
          </div>
          <div class="col-sm-11">
            ustnie – przez telefon, dzwoniąc pod numer 801 159 159 (opłata
            zgodna z taryfą operatora) lub 22 308 35 04, albo osobiście do
            protokołu w każdej jednostce obsługującej klienta;
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-1 d-flex align-items-center justify-content-center">
            <img src={post} alt="mail" />
          </div>
          <div class="col-sm-11">
            pisemnie – przesyłką pocztową w rozumieniu ustawy Prawo pocztowe pod
            adresem: rondo Ignacego Daszyńskiego 4, 00-843 Warszawa (siedziba
            TUW PZUW) albo osobiście w każdej jednostce obsługującej klienta.
          </div>
        </div>
      </div>
      {/*  */}
      <p className="mt-4">
        TUW PZUW rozpatruje reklamację i udziela odpowiedzi bez zbędnej zwłoki,
        nie później niż w ciągu 30 dni od dnia jej otrzymania. W szczególnie
        skomplikowanych przypadkach, uniemożliwiających rozpatrzenie reklamacji
        i udzielenie odpowiedzi w terminie 30 dni, TUW PZUW przekazuje osobie,
        która wystąpiła z reklamacją informację, w której:
      </p>
      <ol>
        <li>wyjaśnia przyczynę opóźnienia;</li>
        <li>
          wskazuje okoliczności, które muszą zostać ustalone dla rozpatrzenia
          sprawy;
        </li>
        {/* <li>wyjaśnia przyczynę opóźnienia;</li> */}
        <li>
          określa przewidywany termin rozpatrzenia reklamacji i udzielenia
          odpowiedzi, który nie może przekroczyć 60 dni od dnia otrzymania
          reklamacji.
          <br />
        </li>
      </ol>
      <span>
        TUW PZUW udziela odpowiedzi na reklamację na piśmie, a na wniosek
        występującego z reklamacją – pocztą elektroniczną.
      </span>
      {/* <span>
        Informujemy, że wskazane w umowie terminy dostępności obowiązują w
        przypadku nie wskazania przez ubezpieczonego preferencji, np.
        konkretnego lekarza, terminu czy godziny lub konkretnej przychodni. W
        przypadku gdy nie możemy dotrzymać gwarantowanych terminów dostępności
        Infolinia podejmuje decyzję o zwrocie kosztów za usługę zrealizowaną w
        innej placówce oraz przekazuje ubezpieczonemu instrukcję dotyczące
        rozliczenia usługi.
      </span> */}
    </React.Fragment>
  );
};

export default Reklamacja;
