import React from 'react';
import { Fields } from 'redux-form';
import {
  FormGroup,
  Label,
  FormFeedback,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  CardFooter
} from 'reactstrap';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import AwsS3 from '@uppy/aws-s3';
import Moment from 'react-moment';
// import ReduxStore from '@uppy/store-redux';
// import { store } from '../../store';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { required } from 'redux-form-validators';
import Polish from '../Uppy/pl';
import classes from './Uploader.module.scss';

class RenderUploader extends React.Component {
  constructor(props) {
    super(props);

    const fieldName = this.props.names[0];

    this.uppy = new Uppy({
      // store: ReduxStore({
      //   store: store,
      //   id: props.name
      // }),
      autoProceed: true
      // debug: true
    })
      .use(AwsS3, {
        getUploadParameters: (file) => {
          return this.props
            .getUploadLink({
              name: file.name,
              type: file.type,
              size: file.size
            })
            .then((successAction) => {
              return successAction.data;
            })
            .catch((errorOrAbortAction) => {
              console.log('error', errorOrAbortAction);
            });
        }
      })
      .on('upload-success', (file, data) => {
        let field = this.props[fieldName];

        if (this.props.isArrayField) {
          field = this.props[this.props.rootKey][this.props.keyIndex][
            this.props.subKey
          ];
        }

        const newKey = file.meta['key'];
        const currentArray = [...field.input.value];
        let newArray = [newKey];
        if (field.input.value) {
          newArray = [...currentArray, newKey];
        }

        field.input.onChange(newArray);

        this.props.formFileUploadAction({
          trigger: fieldName,
          operation: 'add',
          filename: newKey
        });

        this.props.clientFormChange({
          fieldName,
          newValue: newArray,
          oldValue: currentArray
        });

        this.props.serverFormChange({
          trigger: fieldName,
          newValue: newArray,
          oldValue: currentArray
        });
      });
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.uppy.close();
  }

  render() {
    const props = this.props;

    const { globalDisable, viewAsHtml, label, required, isArrayField } = props;

    const fieldName = props.names[0];

    let field = props[fieldName];
    let thumbField = props[props.names[3]];
    let formMeta = props.formMeta[fieldName];

    if (isArrayField) {
      console.log('array props? ', props);
      field = props[props.rootKey][props.keyIndex][props.subKey];
      formMeta = props.formMeta[props.rootKey][props.keyIndex][props.subKey];
    }

    const hidden = formMeta.hidden.input.value;
    const disabled = formMeta.disabled.input.value;

    if (hidden) return null;

    const { touched, error, warning } = field.meta;

    if (viewAsHtml) {
      const fieldInput = thumbField.input.value;
      let fileList = 'Brak załączników.';
      if (fieldInput && Array.isArray(fieldInput) && fieldInput.length > 0) {
        fileList = (
          <React.Fragment>
            <div className={classes.cardContainerBody}>
              {fieldInput.map((file, index) => (
                <Card key={index}>
                  <div
                    style={{
                      backgroundImage: `url("${file.fileThumbnailUrl}")`
                    }}
                    alt="file thumbnail"
                    className={classes.cardImg}
                  />
                  <CardBody>
                    <CardTitle>{file.fileName}</CardTitle>
                    <CardSubtitle>
                      <Moment format="DD.MM.YYYY HH:mm">
                        {file.fileCreated}
                      </Moment>
                    </CardSubtitle>
                  </CardBody>
                  <CardFooter>
                    <Button onClick={() => this.props.download(file.fileId)}>
                      Pobierz
                    </Button>
                  </CardFooter>
                </Card>
              ))}
            </div>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          {!hidden && (
            <div>
              {/*               <Label sm={4}>{label}</Label>
              <Label sm={8} style={{ fontWeight: 'bold' }}>
                {fileList}
              </Label> */}

              {fileList}
            </div>
          )}
        </React.Fragment>
      );
    }

    if (disabled || globalDisable) {
      const fieldInput = field.input.value;
      let fileList = null;
      if (fieldInput && Array.isArray(fieldInput) && fieldInput.length > 0) {
        fileList = (
          <React.Fragment>
            <div>Załączniki:</div>
            <ul>
              {fieldInput.map((file, index) => (
                <li key={index}>{file}</li>
              ))}
            </ul>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          {!hidden && (
            <FormGroup row>
              <Label for={fieldName} sm={4}>
                {label}{' '}
                {required && (
                  <span
                    style={{
                      color: 'red',
                      fontWeight: '500'
                    }}>
                    *
                  </span>
                )}
              </Label>
              <Col sm={8}>
                <div>Upload nowych plików jest zablokowany.</div>
                {fileList}
              </Col>
            </FormGroup>
          )}
        </React.Fragment>
      );
    }

    return (
      <div>
        {!hidden && (
          <FormGroup row>
            <Col>
              {/*               <Label for={fieldName}>
                {label}{' '}
                {required && (
                  <span
                    style={{
                      color: 'red',
                      fontWeight: '500'
                    }}>
                    *
                  </span>
                )}
              </Label> */}
              <div className={touched && !!error ? 'uppyForm invalid' : null}>
                <Dashboard
                  uppy={this.uppy}
                  locale={Polish}
                  height={250}
                  width={8000}
                  showLinkToFileUploadResult={false}
                  showProgressDetails={true}
                  proudlyDisplayPoweredByUppy={false}
                />
              </div>
              {touched &&
                ((error && (
                  <FormFeedback style={{ display: 'block' }}>
                    {error}
                  </FormFeedback>
                )) ||
                  (warning && (
                    <FormFeedback style={{ display: 'block' }}>
                      {warning}
                    </FormFeedback>
                  )))}
            </Col>
          </FormGroup>
        )}
      </div>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     isAuthenticated: state.auth.token !== null,
//     error: state.auth.error,
//     submitting: state.auth.loading
//   };
// };

const mapDispatchToProps = (dispatch) => {
  return {
    getUploadLink: (meta) => {
      return dispatch(actions.getFileUploadUrlPromise(meta));
    },
    download: (fileId) => {
      dispatch(actions.downloadFile(fileId));
    }
  };
};

const ConnectedUploader = connect(null, mapDispatchToProps)(RenderUploader);

class Uploader extends React.Component {
  shouldComponentUpdate(nextProps) {
    const {
      globalDisable,
      viewAsHtml,
      label,
      type,
      clientFormChange,
      serverFormChange
    } = this.props;
    if (
      viewAsHtml !== nextProps.viewAsHtml ||
      globalDisable !== nextProps.globalDisable ||
      label !== nextProps.label ||
      type !== nextProps.type ||
      clientFormChange !== nextProps.clientFormChange ||
      serverFormChange !== nextProps.serverFormChange
    ) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <Fields
        {...this.props}
        globalDisable={this.props.globalDisable}
        names={[
          this.props.name,
          `formMeta.${this.props.name}.hidden`,
          `formMeta.${this.props.name}.disabled`,
          `_thumbnails_${this.props.name}`
        ]}
        component={ConnectedUploader}
        validate={{
          [this.props.name]: (value, allValues, props, name) => {
            // console.log('validate', this.props.inProgress);
            if (this.props.inProgress) {
              return 'Prosimy zaczekać na koniec uploadu.';
            } else if (this.props.validate && this.props.validate.required) {
              return required({ msg: 'Pole wymagane' })(value);
            }
          }
        }}
      />
    );
  }
}

const mapFieldStateToProps = (state, ownProps) => {
  const uploaderState = state.uppy[ownProps.name] || {};
  const currentUploads = uploaderState.currentUploads || {};
  const inProgress = Object.keys(currentUploads).length > 0;

  return {
    inProgress: inProgress
  };
};

// const mapFieldDispatchToProps = (dispatch) => {
//   return {
//     getUploadLink: (meta) => {
//       return dispatch(actions.getFileUploadUrlPromise(meta));
//     }
//   };
// };

export default connect(mapFieldStateToProps, null)(Uploader);
