import React from 'react';

const Ogolne = (props) => {
  return (
    <React.Fragment>
      <h5 className="page-header firstColor ">
        <b>Grupowe Ubezpieczenie TUW PZUW Opieka Medyczna</b> to profesjonalna{' '}
        <b>opieka medyczna</b>, w renomowanych placówkach medycznych w całej
        Polsce dla współpracowników TVP, byłych pracowników TVP oraz członków
        ich rodzin.
      </h5>

      <div className="row">
        <div className="col-sm-6 secondColor">
          <b className="firstColor">
            W ramach Pakietów Medycznych TUW PZUW otrzymujesz:
          </b>
          <ul className="listIcons okCircle mt-3">
            <li>
              wizyty u wykwalifikowanych specjalistów <b>bez skierowań,</b>
            </li>
            <li>
              dostęp do szerokiego katalogu <b>specjalizacji</b> badań{' '}
              <b>i zabiegów</b>, również na podstawie skierowania wystawionego
              poza siecią PZU Zdrowie,
            </li>
            <li>
              <b>gwarancję krótkiego terminu</b> realizacji wizyty u:
            </li>
            <ul className="listIcons ok">
              <li>
                <b>lekarza POZ</b> (internista, lekarz rodzinny, pediatra){' '}
                <b>1 dzień roboczy,</b>
              </li>
              <li>
                <b>specjalistów</b>: chirurga, ginekologa, laryngologa,
                okulisty, ortopedy, dermatologa do <b>3 dni</b> roboczych,{' '}
              </li>
              <li>
                pozostałych specjalistów do <b>7 dni roboczych.</b>
              </li>
            </ul>
          </ul>

          <b className="firstColor">
            Wiek uprawniający do objęcia ubezpieczeniem:
          </b>

          <ul className="listUnstyled">
            <li>
              <b>Współpracownik/małżonek/partner</b> – do ukończenia 67 roku
              życia
            </li>
            <li>
              <b>Dzieci</b> – do ukończenia 26 roku życia,
            </li>
            <li>
              <b>Dorosłe dziecko</b> – dla dzieci, które ukończyły 26 rok życia,
              a nie ukończyły 67 roku życia,
            </li>
            <li>
              <b>Senior</b> – dla rodziców/teściów współpracownika, którzy nie
              ukończyli 80 roku życia
            </li>
          </ul>
        </div>
        <div className="col-sm-6 secondColor">
          <b className="firstColor">
            Masz możliwość wyboru ubezpieczenia w ramach dostępnych pakietów i
            zakresów:
          </b>
          <br />
          <br />
          <b>Zakresy</b>
          <br />

          <ul className="dashedList">
            <li>Podstawowy</li>
            <li>Rozszerzony</li>
            <li>VIP</li>
          </ul>

          <b>Rodzaje pakietów</b>
          <br />
          <br />
          <ol>
            <li>
              <b>Pakiet indywidualny</b>
            </li>
            <li>
              <b>Pakiet partnerski</b>
            </li>
            <li>
              <b>Pakiet rodzinny</b>
              <br />
              <span>
                Członkowie rodziny w pakiecie partnerskim i rodzinnym{' '}
                <b>objęci są tym samym zakresem</b> co współpracownik.
              </span>
            </li>
            <li>
              <b>Dorosłe dziecko</b>
            </li>
            <li>
              <b>Senior</b>
              <br />
              <p>
                Ubezpieczeni w ramach pakietów: dorosłe dziecko, senior mogą
                wybrać inny zakres niż współpracownik, tzn. współpracownik może
                wybrać zakres rozszerzony, a dorosłe dziecko zakres podstawowy.
                Senior i dorosłe dziecko mają 2 zakresy do wyboru: podstawowy i
                rozszerzony.
              </p>
            </li>
          </ol>

          <br />
        </div>
      </div>
      <br />
      <div className="text-white p-2 sixthColor iconFromLeft moneyIcon">
        <h5 className="firstColor">
          <b>Składka za ubezpieczenie</b>
        </h5>
      </div>

      <br />
      <table className="table table-striped table-condensed secondColor">
        <tbody>
          <tr>
            <th className="text-center">Pakiet / zakres</th>
            <th className="text-center">Podstawowy</th>
            <th className="text-center">Rozszerzony</th>
            <th className="text-center">VIP</th>
          </tr>
          <tr>
            <th className="text-center">Indywidualny</th>
            <td className="text-center">93,20 zł</td>
            <td className="text-center">155,40 zł</td>
            <td className="text-center">518,80 zł</td>
          </tr>
          <tr>
            <th className="text-center">
              Partnerski
              <br />
              <small>(dopłata do pakietu indywidualnego)</small>
            </th>
            <td className="text-center">93,20 zł</td>
            <td className="text-center">155,40 zł</td>
            <td className="text-center">518,80 zł</td>
          </tr>
          <tr>
            <th className="text-center">
              Rodzinny
              <br />
              <small>(dopłata do pakietu indywidualnego)</small>
            </th>
            <td className="text-center">186,40 zł</td>
            <td className="text-center">310,90 zł</td>
            <td className="text-center">1037,60 zł</td>
          </tr>
          <tr>
            <th colSpan={4} className="text-center">
              Oddzielna deklaracja na każdego ubezpieczonego:
            </th>
          </tr>
          <tr>
            <th className="text-center">Senior</th>
            <td className="text-center">130,50 zł</td>
            <td className="text-center">310,90 zł</td>
            <td className="text-center">n/d</td>
          </tr>
          <tr>
            <th className="text-center">Dorosłe Dziecko</th>
            <td className="text-center">118,60 zł</td>
            <td className="text-center">282,60 zł</td>
            <td className="text-center">n/d</td>
          </tr>
        </tbody>
      </table>
      <p className="secondColor">
        Składka za ubezpieczenie współpracownika płatna jest na numer subkonta
        bankowego wskazanego na deklaracji przystąpienia do ubezpieczenia.
      </p>
    </React.Fragment>
  );
};

export default Ogolne;
