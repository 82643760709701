import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
// import { setAuthRedirectPath } from '../../store/actions';

import classes from './PasswordRecovery.module.scss';
import PasswordRecoveryForm from '../../components/Forms/PasswordRecoveryForm/PasswordRecoveryForm';
import PasswordRecoveryConfirmForm from '../../components/Forms/PasswordRecoveryForm/PasswordRecoveryConfirmForm';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { Row, Col, Alert } from 'reactstrap';

let Login = (props) => {
  if (props.isAuthenticated) {
    return <Redirect to={props.authRedirectPath} />;
  } else {
    const query = new URLSearchParams(props.location.search);

    const requestSent = query.get('requestSent');
    if (requestSent) {
      return (
        <Row>
          <Col sm="10" md="8" lg="6" xl="5" className={classes.centered}>
            <Alert color="success" fade={false}>
              Link do zmiany hasła zostanie wysłany na podany email jeśli
              istnieje w bazie. Prosimy sprawdzić skrzynkę.
            </Alert>
          </Col>
        </Row>
      );
    }

    let form = <PasswordRecoveryForm />;
    const email = query.get('email');
    const token = query.get('token');
    if (email && token) {
      const tokenFixed = token.split(' ').join('+');
      form = <PasswordRecoveryConfirmForm email={email} token={tokenFixed} />;
    }

    return (
      <Row>
        <Col sm="10" md="8" lg="6" xl="5" className={classes.centered}>
          <GoogleReCaptchaProvider reCaptchaKey="6LfwmGMhAAAAAK4YxJ0Kdx3dASJum_bvYSWUE_jJ">
            {form}
          </GoogleReCaptchaProvider>

          <Alert
            color="secondary"
            fade={false}
            style={{ marginTop: '1rem', backgroundColor: 'inherit' }}>
            {' '}
            Nie masz jeszcze konta? <Link to="/register">Zarejestruj się.</Link>
          </Alert>
        </Col>
      </Row>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath
  };
};

// const mapDispatchToProps = (dispatch, props) => {
//   return {
//     setRedirect: () =>
//       dispatch(setAuthRedirectPath(props.location.state.referrer))
//   };
// };

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(Login);
