import React, { Suspense } from 'react';
import { required, acceptance, email } from 'redux-form-validators';
import { Fields, FieldArray } from 'redux-form';
import { validatePesel } from '../../Validators/pesel';
import {
  min18,
  max25,
  max67,
  min18max67,
  walidatorWspolpracownikPlusPartner,
  walidatorDziecko,
  walidatorDziecko2,
  walidatorSenior,
  walidatorSeniorTuwpzuw,
  walidatorDorosleDziecko,
  kodPocztowy,
  nineDigitPhone,
  emailOrNull
} from '../../Validators/date';
import lodashGet from 'lodash-es/get';

import TextInput from '../../FormComponents/TextInput';
import Checkbox from '../../FormComponents/Checkbox';
import Radio from '../../FormComponents/Radio';
import Select from '../../FormComponents/Select';
import SelectList from '../../FormComponents/SelectList';
import DateTime from '../../FormComponents/DateTime';
import FormButton from '../../FormComponents/Button';
import Uploader from '../../FormComponents/Uploader';
import Tabs from '../../FormComponents/Tabs';
import IntervalRequest from '../../FormComponents/IntervalRequest';
import PdfObject from '../../FormComponents/PdfObject';
import HtmlElement from '../../FormComponents/HtmlElement';
import FieldSet from '../../FormComponents/FieldSet';
import Panel from '../../FormComponents/Panel';
import './FormParser.scss';

import RenderFieldArray from '../../FormComponents/FieldArray';
import RenderMessageArray from '../../FormComponents/MessageArray';

import { Row, Col } from 'reactstrap';

const CarDamage = React.lazy(() =>
  import('../../FormComponents/CarDamage/CarDamage')
);

const parseSchema = (parseProps) => {
  const {
    node,
    change,
    autofill,
    globalDisable,
    viewAsHtml,
    isArrayField = false,
    clientFormChange,
    serverFormChange,
    lookups,
    // clientFormEvent,
    serverFormEvent,
    serverSubmitEvent,
    serverArrayFormChange,
    formFileUploadAction
  } = parseProps;

  switch (node.type) {
    case 'panel':
      return (
        <Panel
          key={node.id}
          parseSchema={parseSchema}
          parseProps={{
            ...parseProps
          }}
          name={node.key}
          theme={node.theme}
          title={node.title}
          customClass={node.customClass}
          components={node.components}
          globalDisable={globalDisable}
          viewAsHtml={viewAsHtml}
        />
      );
    case 'htmlelement':
      return <HtmlElement node={node} key={node.id} />;
    case 'columns':
      return (
        <Row key={node.id}>
          {node.columns.map((subNode) =>
            parseSchema({
              ...parseProps,
              node: subNode
            })
          )}
        </Row>
      );

    case 'column':
      // const disabled = node.disabled || globalDisable
      return (
        <Col key={node.id} className="formColumn">
          {node.components.map((subNode) =>
            parseSchema({
              ...parseProps,
              node: subNode,
              isArrayField: node.isArrayField
            })
          )}
        </Col>
      );
    case 'button':
      return (
        <FormButton
          key={node.id}
          labelColWidth={node.labelMargin || '3'}
          name={node.key}
          globalDisable={globalDisable}
          viewAsHtml={viewAsHtml}
          leftIcon={node.leftIcon}
          customClass={node.customClass}
          size={node.size}
          rightIcon={node.rightIcon}
          label={node.label}
          isArrayField={isArrayField}
          showOnHtmlView={
            node.properties && node.properties.showOnHtmlView
              ? node.properties.showOnHtmlView
              : undefined
          }
          {...(isArrayField
            ? {
                rootKey: node.rootKey,
                subKey: node.subKey,
                keyIndex: node.keyIndex
              }
            : null)}
          color={node.theme}
          onClick={() => {
            console.log(
              `${node.key} pressed, event: ${node.event}, action type ${node.action}`
            );
            switch (node.action) {
              case 'submit':
                serverSubmitEvent({ buttonName: node.key });
                break;
              case 'event':
              default:
                serverFormEvent({
                  customEvent: node.event,
                  buttonName: node.key,
                  validateAll:
                    node.properties && node.properties.validateAll
                      ? node.properties.validateAll
                      : false
                });
                break;
            }
          }}
        />
      );
    case 'intervalRequest':
      return (
        <IntervalRequest
          key={node.id}
          name={node.key}
          label={node.label}
          showSpinner={node.showSpinner}
          interval={node.interval}
          color={node.color}
          action={() =>
            serverFormEvent({
              customEvent: node.event,
              buttonName: node.key,
              hideLoader: true,
              validateAll:
                node.properties && node.properties.validateAll
                  ? node.properties.validateAll
                  : false
            })
          }
        />
      );
    case 'editgrid':
      return (
        <FieldArray
          key={node.id}
          name={node.key}
          component={
            node.properties &&
            node.properties.type &&
            node.properties.type === 'table'
              ? RenderMessageArray
              : RenderFieldArray
          }
          parseSchema={parseSchema}
          parseProps={{
            ...parseProps
          }}
          node={node}
          change={change}
          autofill={autofill}
          globalDisable={globalDisable}
          viewAsHtml={viewAsHtml}
        />
      );
    case 'fieldset':
      return (
        !node.hidden && (
          <Fields
            parseProps={{
              ...parseProps
            }}
            key={node.id}
            names={[
              `formMeta.${node.key}.hidden`,
              `formMeta.${node.key}.disabled`
            ]}
            node={node}
            globalDisable={globalDisable}
            viewAsHtml={viewAsHtml}
            isArrayField={isArrayField}
            parseSchema={parseSchema}
            component={FieldSet}
          />
        )
      );
    case 'checkbox':
      return (
        <Checkbox
          key={node.id}
          labelColWidth={node.labelMargin || '3'}
          name={node.key}
          clientFormChange={clientFormChange}
          serverFormChange={serverFormChange}
          serverArrayFormChange={serverArrayFormChange}
          change={change}
          autofill={autofill}
          globalDisable={globalDisable}
          viewAsHtml={viewAsHtml}
          placeholder={node.placeholder}
          label={node.label}
          required={node.validate.required}
          validate={(value, allValues, props, name) => {
            if (node.validate && node.validate.required && name === node.key) {
              return acceptance({ msg: 'Zgoda jest wymagana' })(value);
            }
          }}
          isArrayField={isArrayField}
          {...(isArrayField
            ? {
                rootKey: node.rootKey,
                subKey: node.subKey,
                keyIndex: node.keyIndex
              }
            : null)}
        />
      );
    case 'radio':
      return (
        <Radio
          key={node.id}
          labelColWidth={node.labelMargin || '3'}
          name={node.key}
          change={change}
          autofill={autofill}
          globalDisable={globalDisable}
          viewAsHtml={viewAsHtml}
          placeholder={node.placeholder}
          clientFormChange={clientFormChange}
          serverFormChange={serverFormChange}
          serverArrayFormChange={serverArrayFormChange}
          label={node.label}
          values={node.values}
          variant={
            node.properties && node.properties.variant
              ? node.properties.variant
              : undefined
          }
          required={node.validate.required}
          validate={{
            [node.key]: (value, allValues, props, name) => {
              if (node.validate && node.validate.required) {
                return required({ msg: 'Pole wymagane' })(value);
              }
            }
          }}
          isArrayField={isArrayField}
          {...(isArrayField
            ? {
                rootKey: node.rootKey,
                subKey: node.subKey,
                keyIndex: node.keyIndex
              }
            : null)}
        />
      );
    case 'select':
      let SelectType = Select;
      if (node.multiple) {
        SelectType = SelectList;
      }
      return (
        <SelectType
          key={node.id}
          labelColWidth={node.labelMargin || '3'}
          name={node.key}
          label={node.label}
          clientFormChange={clientFormChange}
          serverFormChange={serverFormChange}
          globalDisable={globalDisable}
          viewAsHtml={viewAsHtml}
          placeholder={node.placeholder}
          values={lookups[node.key] || lookups[node.subKey] || []}
          required={node.validate ? node.validate.required : false}
          validate={{
            [node.key]: (value, allValues, props, name) => {
              if (node.validate && node.validate.required) {
                return required({ msg: 'Pole wymagane' })(value);
              }
            }
          }}
          isArrayField={isArrayField}
          {...(isArrayField
            ? {
                rootKey: node.rootKey,
                subKey: node.subKey,
                keyIndex: node.keyIndex
              }
            : null)}
        />
      );
    case 'textarea':
      return (
        <TextInput
          key={node.id}
          labelColWidth={node.labelMargin || '3'}
          name={node.key}
          change={change}
          type="textarea"
          rows="5"
          autofill={autofill}
          globalDisable={globalDisable}
          viewAsHtml={viewAsHtml}
          clientFormChange={clientFormChange}
          serverFormChange={serverFormChange}
          serverArrayFormChange={serverArrayFormChange}
          placeholder={node.placeholder}
          label={node.label}
          showOnHtmlView={
            node.properties && node.properties.showOnHtmlView
              ? node.properties.showOnHtmlView
              : undefined
          }
          required={node.validate ? node.validate.required : false}
          validate={{
            [node.key]: (value, allValues, props, name) => {
              if (node.validate && node.validate.required) {
                return required({ msg: 'Pole wymagane' })(value);
              }
            }
          }}
          isArrayField={isArrayField}
          {...(isArrayField
            ? {
                rootKey: node.rootKey,
                subKey: node.subKey,
                keyIndex: node.keyIndex
              }
            : null)}
        />
      );
    case 'datetime':
      return (
        <DateTime
          key={node.id}
          labelColWidth={node.labelMargin || '3'}
          name={node.key}
          change={change}
          type="text"
          dateFormat={node.format || 'YYYY-MM-DD'}
          autofill={autofill}
          globalDisable={globalDisable}
          viewAsHtml={viewAsHtml}
          clientFormChange={clientFormChange}
          serverFormChange={serverFormChange}
          serverArrayFormChange={serverArrayFormChange}
          placeholder={node.placeholder}
          label={node.label}
          required={node.validate ? node.validate.required : false}
          validate={{
            [node.key]: (value, allValues, props, name) => {
              const meta = allValues.formMeta
                ? lodashGet(allValues.formMeta, name, {})
                : {};

              if (!meta.hidden && !meta.disabled) {
                if (node.validate && node.validate.required) {
                  const requiredValidation = required({
                    msg: 'Pole wymagane'
                  })(value);
                  if (requiredValidation) return requiredValidation;
                }
                if (node.validate && node.validate.custom) {
                  switch (node.validate.custom) {
                    case 'min18':
                      const min18validation = min18(value);
                      if (min18validation) return min18validation;
                      break;
                    case 'max25':
                      const max25validation = max25(value);
                      if (max25validation) return max25validation;
                      break;
                    case 'max67':
                      const max67validation = max67(value);
                      if (max67validation) return max67validation;
                      break;
                    case 'min18max67':
                      const min18max67validation = min18max67(value);
                      if (min18max67validation) return min18max67validation;
                      break;
                    case 'walidatorDziecko':
                      const walidatorDzieckovalidation =
                        walidatorDziecko(value);
                      if (walidatorDzieckovalidation)
                        return walidatorDzieckovalidation;
                      break;
                    case 'walidatorDziecko2':
                      const walidatorDziecko2validation =
                        walidatorDziecko2(value);
                      if (walidatorDziecko2validation)
                        return walidatorDziecko2validation;
                      break;
                    case 'walidatorWspolpracownikPlusPartner':
                      const walidatorWspolpracownikPlusPartnervalidation =
                        walidatorWspolpracownikPlusPartner(value);
                      if (walidatorWspolpracownikPlusPartnervalidation)
                        return walidatorWspolpracownikPlusPartnervalidation;
                      break;
                    case 'walidatorDorosleDziecko':
                      const walidatorDorosleDzieckovalidation =
                        walidatorDorosleDziecko(value);
                      if (walidatorDorosleDzieckovalidation)
                        return walidatorDorosleDzieckovalidation;
                      break;
                    case 'walidatorSenior':
                      const walidatorSeniorvalidation = walidatorSenior(value);
                      if (walidatorSeniorvalidation)
                        return walidatorSeniorvalidation;
                      break;
                    case 'walidatorSeniorTuwpzuw':
                      const walidatorSeniorTuwpzuwvalidation =
                        walidatorSenior(value);
                      if (walidatorSeniorTuwpzuwvalidation)
                        return walidatorSeniorTuwpzuwvalidation;
                      break;
                    default:
                      break;
                  }
                }
              }
              return undefined;
            }
          }}
          isArrayField={isArrayField}
          {...(isArrayField
            ? {
                rootKey: node.rootKey,
                subKey: node.subKey,
                keyIndex: node.keyIndex
              }
            : null)}
        />
      );
    case 'file':
      return (
        <Uploader
          key={node.id}
          labelColWidth={node.labelMargin || '3'}
          name={node.key}
          change={change}
          type="text"
          autofill={autofill}
          globalDisable={globalDisable}
          viewAsHtml={viewAsHtml}
          clientFormChange={clientFormChange}
          serverFormChange={serverFormChange}
          serverArrayFormChange={serverArrayFormChange}
          formFileUploadAction={formFileUploadAction}
          placeholder={node.placeholder}
          label={node.label}
          required={node.validate ? node.validate.required : false}
          validate={node.validate}
          isArrayField={isArrayField}
          {...(isArrayField
            ? {
                rootKey: node.rootKey,
                subKey: node.subKey,
                keyIndex: node.keyIndex
              }
            : null)}
        />
      );
    case 'tabs':
      return (
        <Tabs
          node={node}
          key={node.key}
          parseSchema={parseSchema}
          parseProps={{
            ...parseProps
          }}
        />
      );
    case 'pdfObject':
      return (
        <PdfObject
          key={node.id}
          labelColWidth={node.labelMargin || '3'}
          name={node.key}
          change={change}
          type="text"
          autofill={autofill}
          globalDisable={globalDisable}
          viewAsHtml={viewAsHtml}
          clientFormChange={clientFormChange}
          serverFormChange={serverFormChange}
          serverArrayFormChange={serverArrayFormChange}
          placeholder={node.placeholder}
          label={node.label}
          required={node.validate ? node.validate.required : false}
          validate={{
            [node.key]: (value, allValues, props, name) => {
              if (node.validate && node.validate.required) {
                return required({ msg: 'Pole wymagane' })(value);
              }
            }
          }}
          isArrayField={isArrayField}
          {...(isArrayField
            ? {
                rootKey: node.rootKey,
                subKey: node.subKey,
                keyIndex: node.keyIndex
              }
            : null)}
        />
      );
    case 'carDamage':
      return (
        <Suspense fallback={<div>Loading...</div>} key={node.id}>
          <CarDamage
            key={node.id}
            labelColWidth={node.labelMargin || '3'}
            name={node.key}
            change={change}
            autofill={autofill}
            globalDisable={globalDisable}
            viewAsHtml={viewAsHtml}
            clientFormChange={clientFormChange}
            serverFormChange={serverFormChange}
            serverArrayFormChange={serverArrayFormChange}
            placeholder={node.placeholder}
            label={node.label}
            required={node.validate ? node.validate.required : false}
            validate={{
              [node.key]: (value, allValues, props, name) => {
                if (node.validate && node.validate.required) {
                  return required({ msg: 'Pole wymagane' })(value);
                }
              }
            }}
            isArrayField={isArrayField}
            {...(isArrayField
              ? {
                  rootKey: node.rootKey,
                  subKey: node.subKey,
                  keyIndex: node.keyIndex
                }
              : null)}
          />
        </Suspense>
      );
    case 'selectboxes':
    case 'textfield':
    case 'number':
    case 'email':
    default:
      return (
        <TextInput
          key={node.id}
          labelColWidth={node.labelMargin || '3'}
          name={node.key}
          change={change}
          type="text"
          autofill={autofill}
          globalDisable={globalDisable}
          viewAsHtml={viewAsHtml}
          clientFormChange={clientFormChange}
          serverFormChange={serverFormChange}
          serverArrayFormChange={serverArrayFormChange}
          placeholder={node.placeholder}
          label={node.label}
          required={node.validate ? node.validate.required : false}
          validate={{
            [node.key]: (value, allValues, props, name) => {
              const meta = allValues.formMeta
                ? lodashGet(allValues.formMeta, name, {})
                : {};

              if (!meta.hidden) {
                if (node.validate && node.validate.required) {
                  const requiredValidation = required({
                    msg: 'Pole wymagane'
                  })(value);
                  if (requiredValidation) return requiredValidation;
                }
                if (node.validate && node.validate.custom) {
                  switch (node.validate.custom) {
                    case 'email':
                      const emailValidation = email({
                        msg: 'Wymagany jest poprawny email'
                      })(value);
                      if (emailValidation) return emailValidation;
                      break;
                    case 'pesel':
                      const peselValidation = validatePesel(value);
                      if (peselValidation) return peselValidation;
                      break;
                    case 'kodPocztowy':
                      const kodPocztowyValidation = kodPocztowy(value);
                      if (kodPocztowyValidation) return kodPocztowyValidation;
                      break;
                    case 'nineDigitPhone':
                      const nineDigitPhoneValidation = nineDigitPhone(value);
                      if (nineDigitPhoneValidation)
                        return nineDigitPhoneValidation;
                      break;
                    case 'emailOrNull':
                      const emailOrNullValidation = emailOrNull(value);
                      if (emailOrNullValidation) return emailOrNullValidation;
                      break;
                    case 'peselMin18':
                      const peselMin18 = validatePesel(value, 18, null);
                      if (peselMin18) return peselMin18;
                      break;
                    case 'peselMax25':
                      const peselMax25 = validatePesel(value, null, 26);
                      if (peselMax25) return peselMax25;
                      break;
                    case 'peselMin18Max67':
                      const peselMin18Max67 = validatePesel(
                        value,
                        18,
                        null,
                        true
                      );
                      if (peselMin18Max67) return peselMin18Max67;
                      break;
                    case 'peselMax67':
                      const peselMax67 = validatePesel(value, null, null, true);
                      if (peselMax67) return peselMax67;
                      break;
                    case 'walidatorDziecko':
                      const walidatorDzieckoP = validatePesel(
                        value,
                        false,
                        false,
                        false,
                        'walidatorDziecko'
                      );
                      if (walidatorDzieckoP) return walidatorDzieckoP;
                      break;
                    case 'walidatorDziecko2':
                      const walidatorDzieckoP2 = validatePesel(
                        value,
                        false,
                        false,
                        false,
                        'walidatorDziecko2'
                      );
                      if (walidatorDzieckoP2) return walidatorDzieckoP2;
                      break;
                    case 'walidatorWspolpracownikPlusPartner':
                      const walidatorWspolpracownikPlusPartnerP = validatePesel(
                        value,
                        false,
                        false,
                        false,
                        'walidatorWspolpracownikPlusPartner'
                      );
                      if (walidatorWspolpracownikPlusPartnerP)
                        return walidatorWspolpracownikPlusPartnerP;
                      break;
                    case 'walidatorDorosleDziecko':
                      const walidatorDorosleDzieckoP = validatePesel(
                        value,
                        false,
                        false,
                        false,
                        'walidatorDorosleDziecko'
                      );
                      if (walidatorDorosleDzieckoP)
                        return walidatorDorosleDzieckoP;
                      break;
                    case 'walidatorSenior':
                      const walidatorSeniorP = validatePesel(
                        value,
                        false,
                        false,
                        false,
                        'walidatorSenior'
                      );
                      if (walidatorSeniorP) return walidatorSeniorP;
                      break;
                    case 'walidatorSeniorTuwpzuw':
                      const walidatorSeniorTuwpzuwP = validatePesel(
                        value,
                        false,
                        false,
                        false,
                        'walidatorSeniorTuwpzuw'
                      );
                      if (walidatorSeniorTuwpzuwP)
                        return walidatorSeniorTuwpzuwP;
                      break;
                    default:
                      break;
                  }
                }
              }
              return undefined;
            }
          }}
          isArrayField={isArrayField}
          {...(isArrayField
            ? {
                rootKey: node.rootKey,
                subKey: node.subKey,
                keyIndex: node.keyIndex
              }
            : null)}
        />
      );
  }
};

export default parseSchema;
