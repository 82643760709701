import React, { Component } from 'react';
import { connect } from 'react-redux';
import { authCheckState } from '../../store/actions/index';
import { Helmet } from 'react-helmet';

import { Route, Switch, Redirect } from 'react-router-dom';
import NavBar from './NavBar/NavBar';
import Pracownicy from '../../pages/TVP/Pracownicy';
import Kontakt from '../../pages/TVP/Kontakt';
import Wspolpracownicy from '../../pages/TVP/Wspolpracownicy';
import Senior from '../../pages/TVP/Senior';
import DorosleDziecko from '../../pages/TVP/DorosleDziecko';
import TvpDashboard from '../../pages/TVP/Dashboard';
import InactiveMonitor from './Inactive';

import './scss/index.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowAltCircleRight,
  faUserCircle,
  faCircleNotch,
  faPlusCircle,
  faPaperclip,
  faTimes,
  faCheck,
  faSignOutAlt,
  faSignInAlt,
  faListUl,
  faDotCircle,
  faUserFriends,
  faExchangeAlt,
  faFileAlt,
  faFileSignature,
  faBookMedical,
  faLifeRing,
  faMedkit,
  faUserEdit,
  faEnvelope,
  faFileInvoice,
  faPrint,
  faPhone,
  faFileDownload,
  faDownload,
  faKey
} from '@fortawesome/free-solid-svg-icons';

import Footer from './Footer/Footer';
import ModalController from '../../components/ModalController/ModalController';
import Login from '../../pages/Login/Login';
import Logout from '../../pages/Logout/Logout';
import Register from '../../pages/Register/Register';
import Dynamic from '../../pages/Dynamic/Dynamic';
import ChangeProgram from '../../pages/ChangeProgram/ChangeProgram';
import ChangePassword from '../../pages/ChangePassword/ChangePassword';
import ActivationExpired from '../../pages/ActivationExpired/ActivationExpired';
import PasswordRecovery from '../../pages/PasswordRecovery/PasswordRecovery';
import { Row, Col, Alert } from 'reactstrap';

library.add(
  faArrowAltCircleRight,
  faUserCircle,
  faCircleNotch,
  faPlusCircle,
  faPaperclip,
  faTimes,
  faCheck,
  faSignOutAlt,
  faSignInAlt,
  faListUl,
  faDotCircle,
  faUserFriends,
  faExchangeAlt,
  faFileAlt,
  faFileSignature,
  faPhone,
  faBookMedical,
  faLifeRing,
  faMedkit,
  faUserEdit,
  faEnvelope,
  faFileInvoice,
  faPrint,
  faFileDownload,
  faDownload,
  faKey
);

const TempBlock = () => {
  return (
    <Row>
      <Col xs="12">
        <Alert
          color="danger"
          fade={false}
          style={{
            margin: '0rem 0rem 1rem 0rem',
            textAlign: 'justify'
          }}>
          <span
            style={{
              fontWeight: '600'
            }}>
            Nowym program Ubezpieczeń Medycznych TVP oraz TUW PZUW rusza od 1
            grudnia 2022. Wkrótce poinformujemy Państwa o dacie rozpoczęcia
            rejestracji i składania wniosków.
          </span>
        </Alert>
      </Col>
    </Row>
  );
};

class TvpLayout extends Component {
  render() {
    const { isAuthenticated, hasPrograms, tuwpzuw, firstMenuItem } = this.props;
    const isTvpTuw =
      window.location.hostname === 'tvp-tuwpzuw.uspartner.pl' &&
      new Date('2022-10-24').getTime() > new Date().getTime();
    return (
      <React.Fragment>
        <Helmet>
          <link
            rel="apple-touch-icon"
            // sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            // sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            // sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#1e559a" />
          <meta name="msapplication-TileColor" content="#1e559a" />
          <meta name="theme-color" content="#ffffff" />
          {(tuwpzuw || !isAuthenticated) && (
            <title>
              Grupowe Ubezpieczenie TUW PZUW Opieka Medyczna (OM) - Kontynuacja
            </title>
          )}
          {isAuthenticated && !tuwpzuw && <title>Ares</title>}
        </Helmet>
        <ModalController />
        <NavBar />
        <InactiveMonitor />
        <div className="mainContainer">
          <div className="mainContent fixedWidth">
            <Switch>
              {(tuwpzuw || !isAuthenticated) && (
                <Route path="/" exact component={TvpDashboard} />
              )}
              {isAuthenticated && (
                <Route
                  path="/change-password"
                  exact
                  component={ChangePassword}
                />
              )}
              <Route
                path="/"
                exact
                render={(props) => {
                  if (!firstMenuItem) return null;
                  return <Dynamic {...props} guid={firstMenuItem} />;
                }}
              />
              <Route
                path="/login"
                exact
                component={isTvpTuw ? TempBlock : Login}
              />
              <Route
                path="/password-recovery"
                exact
                component={isTvpTuw ? TempBlock : PasswordRecovery}
              />
              <Route
                path="/logout"
                exact
                component={isTvpTuw ? TempBlock : Logout}
              />
              <Route
                path="/register"
                exact
                component={isTvpTuw ? TempBlock : Register}
              />
              <Route
                path="/activationExpired"
                exact
                component={isTvpTuw ? TempBlock : ActivationExpired}
              />
              <Route path="/kontakt" exact component={Kontakt} />
              <Route path="/404" render={() => <div>404</div>} />

              <Route
                path="/pracownicy/"
                render={(props) => (
                  <Pracownicy
                    {...props}
                    section="TVP Pracownicy"
                    rootSection="Ubezpieczenia"
                  />
                )}
              />
              <Route
                path="/wspolpracownicy/"
                render={(props) => (
                  <Wspolpracownicy
                    {...props}
                    section="TVP Współpracownicy"
                    rootSection="Ubezpieczenia"
                  />
                )}
              />
              <Route
                path="/senior/"
                render={(props) => (
                  <Senior
                    {...props}
                    section="Senior"
                    rootSection="Ubezpieczenia"
                  />
                )}
              />
              <Route
                path="/kontynuacja/"
                render={(props) => (
                  <DorosleDziecko
                    {...props}
                    section="Kontynuacja"
                    rootSection="Ubezpieczenia"
                  />
                )}
              />
              {isAuthenticated && (
                <Route
                  path="/:guid([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})"
                  // component={Dynamic}
                  render={(props) => {
                    return (
                      <Dynamic
                        {...props}
                        guid={props.match.params.guid}
                        // key={props.match.params.guid}
                      />
                    );
                  }}
                />
              )}
              {hasPrograms && isAuthenticated && (
                <Route path="/changeprogram" component={ChangeProgram} />
              )}
              <Redirect
                from="/:guid([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})"
                // to="/login"
                to={{
                  pathname: '/login',
                  state: { referrer: this.props.location.pathname }
                }}
              />
              <Redirect to="/404" />
            </Switch>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const programs = state.auth.programs || [];
  const menu =
    state.menu.data &&
    state.menu.data.menu &&
    Array.isArray(state.menu.data.menu)
      ? state.menu.data.menu
      : undefined;
  let firstItem;
  if (menu && menu.length > 0) {
    if (menu[0].pageId) {
      firstItem = menu[0].pageId;
    } else if (menu[0].nestedElements) {
      firstItem = menu[0].nestedElements[0].pageId;
    }
  }

  return {
    isAuthenticated: state.auth.token !== null,
    tuwpzuw:
      state.auth.user.programId === 'b6f47e72-716e-4e3d-9ce1-797637bf3b69',
    hasPrograms: programs.length > 1 ? true : false,
    firstMenuItem: firstItem
  };
};

const mapDispatchToProps = (dispatch) => {
  return { authCheckState: () => dispatch(authCheckState()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(TvpLayout);
