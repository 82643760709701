import React from 'react';
// import { Link } from 'react-router-dom';

const Ogolne = (props) => {
  return (
    <React.Fragment>
      <h5 className="page-header firstColor ">
        <b>Grupowe Ubezpieczenie TUW PZUW Opieka Medyczna</b> to profesjonalna{' '}
        <b>opieka medyczna</b>, w renomowanych placówkach medycznych w całej
        Polsce dla współpracowników TVP, byłych pracowników TVP oraz członków
        ich rodzin.
      </h5>

      <div className="secondColor">
        <p>
          Pakiet <b>senior</b> dostępny jest w dwóch zakresach:
        </p>
      </div>

      <ul className="listIcons ok">
        <li>Podstawowym</li>
        <li>Rozszerzonym</li>
      </ul>
      <p className="firstColor">
        Zakres pakietu senior jest identyczny z zakresem pakietu podstawowego i
        rozszerzonego dla pracownika, współpracownika.
      </p>
      <p className="secondColor">
        Jeżeli ubezpieczasz rodziców/teściów w ramach pakietu senior{' '}
        <b>możesz wybrać dla nich inny zakres</b> niż dla siebie tzn. jeżeli
        wybrałeś dla siebie zakres podstawowy, dla seniora możesz wybrać zakres
        rozszerzony.
      </p>

      <br />
      <div className="text-white p-2 sixthColor iconFromLeft moneyIcon">
        <h5 className="firstColor">
          <b>Składka za ubezpieczenie</b>
        </h5>
      </div>
      <br />
      <table className="table table-striped table-condensed secondColor">
        <tbody>
          <tr>
            <th className="text-center">Pakiet / zakres</th>
            <th className="text-center">Podstawowy</th>
            <th className="text-center">Rozszerzony</th>
          </tr>
          <tr>
            <th className="text-center">Senior</th>
            <td className="text-center">130,50 zł</td>
            <td className="text-center">310,90 zł</td>
          </tr>
        </tbody>
      </table>
      <br />
      <p className="secondColor">
        Składka za ubezpieczenie płatna jest na numer subkonta bankowego
        wskazanego na deklaracji przystąpienia do ubezpieczenia.
      </p>
    </React.Fragment>
  );
};

export default Ogolne;
