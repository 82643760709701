import React from 'react';
import calendar2 from './../../../assets/img/brand/pzu/calendar2.png';
import info from './../../../assets/img/brand/pzu/info.png';
import arrowRight from './../../../assets/img/brand/pzu/arrowRight.png';
import exclamationMark from './../../../assets/img/brand/pzu/exclamationMark.png';

const ZasadyPrzystepowania = (props) => {
  return (
    <React.Fragment>
      <h3 className="page-header firstColor">
        Jak przystąpić do ubezpieczenia zdrowotnego
      </h3>
      <div className="secondColor">
        <p>
          Aby przystąpić do ubezpieczenia zarejestruj się i{' '}
          <b>wypełnij formularz online dla siebie i swoich najbliższych.</b>
        </p>
        <p>
          Oferta skierowana jest do:
          <ul>
            <li>obecnych współpracowników</li>
            <li>
              byłych pracowników w ciągu trzech miesięcy od daty ustania
              stosunku pracy
            </li>
          </ul>
        </p>
        <div className="container mt-4">
          <div className="row">
            <div className="col-sm-1 d-flex align-items-center">
              <img src={arrowRight} alt="calendar" />
            </div>
            <div className="col-sm">
              Wydrukuj i <b>podpisz dokumenty</b> wraz ze{' '}
              <b>wszystkimi zgłoszonymi członkami rodziny</b> (w przypadku
              niepełnoletniego dziecka deklarację podpisuje opiekun prawny).
            </div>
          </div>

          <div className="row">
            <div className="col-sm-1 d-flex align-items-center"></div>
            <div className="col-sm pl-0">
              <ul className="listArrowRight">
                <li className="mt-4">
                  Podpisaną deklarację prześlij w formie skanu lub zdjęcia na
                  adres mailowy{' '}
                  <a href="mailto:deklaracje@datus.pl" className="firstColor">
                    <b>deklaracje@datus.pl</b>
                  </a>{' '}
                  lub listownie na adres:
                  <br />
                  <br /> Datus Sp. z o.o.,
                  <br /> ul. Obrzeżna 7,
                  <br /> 02-691 Warszawa
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container mt-3">
          <div className="row">
            <div className="col-sm-1 d-flex align-items-center">
              <img src={calendar2} alt="calendar" />
            </div>
            <div className="col-sm">
              Aby zostać objętym ubezpieczeniem zdrowotnym od{' '}
              <b>1 dnia miesiąca</b> wypełnij, opłać i prześlij deklarację do{' '}
              <b>
                20 dnia miesiąca poprzedzającego rozpoczęcie ochrony
                ubezpieczeniowej
              </b>
              , np. jeśli wypełnisz deklarację do <b>20 listopada</b> opieka
              medyczna rozpocznie się od <b>1 grudnia</b>, jeśli wypełnisz{' '}
              <b>20 grudnia</b> opieka medyczna rozpocznie się <b>1 stycznia</b>
              .
            </div>
          </div>
          <div className="row">
            <div className="col-sm-1 d-flex align-items-center"></div>
            <div className="col-sm">
              <br />
              Pamiętaj, że składki za ubezpieczenie{' '}
              <b>opłacane są indywidualnie</b> na wskazany w deklaracji numer
              subkonta bankowego. Nieterminowe opłacenie składki będzie
              skutkowało zakończeniem ochrony.
            </div>
          </div>
        </div>
        <br />
        <span>
          Możesz również objąć ubezpieczeniem członków swojej rodziny,
          wybierając odpowiedni pakiet:
        </span>
        <ul className="mt-3">
          <li>
            Pakiet Partnerski – dla współpracownika i jednego członka rodziny
            (małżonka/partnera życiowego albo dziecka*)
          </li>
          <li>
            Pakiet Rodzinny – dla współpracownika i dwóch lub więcej członków
            rodziny (małżonka/partnera życiowego i/lub dzieci bez względu na ich
            liczbę*),
            <br />
            <br />
            <small>
              *Dziecko własne lub przysposobione, pozostające na utrzymaniu
              współpracownika TVP, do ukończenia 26 roku życia.
            </small>
          </li>
        </ul>

        <div className="container mt-4">
          <div className="row">
            <div className="col-sm-1 text-right">
              <img src={exclamationMark} alt="calendar" />
            </div>
            <div className="col-sm">
              <b>Nowa propozycja dostępna w ramach ubezpieczenia TUW PZUW</b>
              <br />
              Masz możliwość rozszerzyć opiekę medyczną również na:
            </div>
          </div>
          <div className="row">
            <div className="col-sm-1 d-flex align-items-center"></div>
            <div className="col-sm">
              <ul className="mt-3">
                <li>
                  Dorosłe dziecko – dla dorosłego dziecka współpracownika, które
                  ukończyło 26 r.ż.
                </li>
                <li>
                  Seniora – dla rodziców i teściów współpracowników TVP, którzy
                  nie ukończyli 80 r.ż
                </li>
              </ul>
            </div>
          </div>
        </div>

        <span className="mt-4">
          Deklarację przystąpienia do ubezpieczenia dla dorosłego dziecka i
          seniora złóż również poprzez formularz on-line wchodząc w odpowiednią
          sekcję „Senior” lub „Dorosłe Dziecko”. Znajdziesz tam zasady płatności
          za pakiety oraz terminy składania deklaracji.
        </span>
        <div className="container mt-4">
          <div className="row">
            <div className="col-sm-1 text-right">
              <img src={info} alt="calendar" />
            </div>
            <div className="col-sm">
              <b>Pamiętaj:</b> aby zgłosić do ubezpieczenia członków rodziny w
              tym seniora lub dorosłe dziecko musisz mieć aktywny pakiet opieki
              medycznej.
            </div>
          </div>
        </div>
        <br />
        <br />
        <span>
          <b className="firstColor">
            Możesz dokonać zmiany pakietu lub zakresu ubezpieczenia wypełniając
            nową deklarację przystąpienia
          </b>
        </span>
        <ul>
          <li>
            zmiana pakietu z indywidualnego na pakiet partnerski lub rodzinny
            jest możliwa w <b>dowolnym momencie</b> z datą obowiązywania od 1
            dnia kolejnego miesiąca,
          </li>
          <li>
            zmiana pakietu z rodzinnego na partnerski lub indywidualny, bądź z
            partnerskiego na indywidualny jest możliwa w dowolnym momencie z
            datą obowiązywania od 1 dnia kolejnego miesiąca,
          </li>
          <li>
            zmiana zakresu z <b>„niższego na wyższy”</b> (tj. z zakresu
            podstawowego na zakres rozszerzony lub zakres VIP, bądź z
            rozszerzonego na VIP) jest możliwa w dowolnym momencie z datą
            obowiązywania od 1 dnia kolejnego miesiąca,
          </li>
          <li>
            zmiana zakresu z „wyższego na niższy” (tj. z zakresu VIP na zakres
            rozszerzony lub zakres podstawowy, bądź z rozszerzonego na
            podstawowy jest możliwa <b>2 razy w roku</b> w określonych
            terminach, tj.
            <br />
            <br />
            <ul>
              <li>
                Dla deklaracji złożonych w okresie od 21 października do 20
                listopada, ze skutkiem od 1 grudnia.
              </li>
              <li>
                Dla deklaracji złożonych w okresie od 21 kwietnia do 20 maja, ze
                skutkiem od 1 czerwca.
              </li>
            </ul>
            <br />
          </li>
        </ul>
        <b className="firstColor">Rezygnacja</b>
        <br />

        <p>
          W celu rezygnacji z ubezpieczenia, po zalogowaniu się na swoje konto,
          należy w sekcji <b>Moje wnioski</b>, zaznaczyć w aktywnym wniosku
          opcję <b>Zrezygnuj</b>.
        </p>

        <p>
          Nieopłacenie składki w terminie będzie skutkowało zakończeniem
          ubezpieczenia.
        </p>
        <p>
          <b>
            Ponowne przystąpienie do ubezpieczenia jest możliwe po 6 pełnych
            miesiącach od zakończenia ochrony ubezpieczeniowej.
          </b>
        </p>
      </div>
    </React.Fragment>
  );
};

export default ZasadyPrzystepowania;
