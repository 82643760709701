import React from 'react';

const Ogolne = (props) => {
  return (
    <React.Fragment>
      <h5 className="page-header firstColor ">
        <b>Grupowe Ubezpieczenie TUW PZUW Opieka Medyczna</b> to profesjonalna{' '}
        <b>opieka medyczna</b>, w renomowanych placówkach medycznych w całej
        Polsce dla Ciebie i członków Twojej rodziny.
      </h5>
      <div className="row">
        <div className="col-sm-6 secondColor">
          <b className="firstColor">
            W ramach Pakietów Medycznych TUW PZUW otrzymujesz:
          </b>

          <ul className="listIcons okCircle mt-3">
            <li>
              wizyty u wykwalifikowanych specjalistów <b>bez skierowań,</b>
            </li>
            <li>
              dostęp do szerokiego katalogu <b>specjalizacji</b> badań{' '}
              <b>i zabiegów</b>, również na podstawie skierowania wystawionego
              poza siecią PZU Zdrowie,
            </li>
            <li>
              <b>gwarancję krótkiego terminu</b> realizacji wizyty u:
            </li>
            <ul className="listIcons ok">
              <li>
                <b>lekarza POZ</b> (internista, lekarz rodzinny, pediatra){' '}
                <b>1 dzień roboczy,</b>
              </li>
              <li>
                <b>specjalistów</b>: chirurga, ginekologa, laryngologa,
                okulisty, ortopedy, dermatologa do <b>3 dni</b> roboczych,{' '}
              </li>
              <li>
                pozostałych specjalistów do <b>7 dni roboczych.</b>
              </li>
            </ul>
          </ul>

          <b className="firstColor">
            Wiek uprawniający do objęcia ubezpieczeniem:
          </b>

          <ul className="listUnstyled">
            <li>
              <b>Pracownik/małżonek/partner</b> – bez ograniczeń wiekowych
            </li>
            <li>
              <b>Dzieci</b> – do ukończenia 26 roku życia,
            </li>
            <li>
              <b>Dorosłe dziecko</b> – dla dzieci, które ukończyły 26 rok życia,
              a nie ukończyły 67 roku życia,
            </li>
            <li>
              <b>Senior</b> – dla rodziców/teściów pracownika, którzy nie
              ukończyli 80 roku życia
            </li>
          </ul>
        </div>
        <div className="col-sm-6 secondColor">
          <b className="firstColor">
            Masz możliwość wyboru ubezpieczenia w ramach dostępnych pakietów i
            zakresów:
          </b>
          <br />
          <br />
          <b>Zakresy</b>
          <ul className="dashedList">
            <li>Podstawowy</li>
            <li>Rozszerzony</li>
            <li>VIP</li>
          </ul>

          <b>Rodzaje pakietów</b>
          <br />
          <br />
          <ol>
            <li>
              <b>Pakiet indywidualny</b>
            </li>
            <li>
              <b>Pakiet partnerski</b>
            </li>
            <li>
              <b>Pakiet rodzinny</b>
              <br />
              <span>
                Członkowie rodziny w pakiecie partnerskim i rodzinnym{' '}
                <b>objęci są tym samym zakresem</b> co pracownik.
              </span>
            </li>
            <li>
              <b>Dorosłe dziecko</b>
            </li>
            <li>
              <b>Senior</b>
              <br />
              <p>
                Ubezpieczeni w ramach pakietów: dorosłe dziecko, senior mogą
                wybrać inny zakres niż pracownik, tzn. pracownik może wybrać
                zakres rozszerzony, a dorosłe dziecko zakres podstawowy. Senior
                i dorosłe dziecko mają 2 zakresy do wyboru: podstawowy i
                rozszerzony.
              </p>
            </li>
          </ol>

          <br />
        </div>
      </div>
      <br />

      <div className="text-white p-2 sixthColor iconFromLeft moneyIcon">
        <h5 className="firstColor">
          <b>Składka za ubezpieczenie</b>
        </h5>
      </div>
      <br />

      <table className="table table-bordered secondColor">
        <tbody className="text-center">
          <tr className=" firstColor">
            <th colSpan={2} className="col-sm-4">
              PAKIET ZDROWOTNY DLA PRACOWNIKÓW
              <br />
              (opłata za miesiąc)
            </th>
            <th className="col-sm-2">PAKIET PODSTAWOWY</th>
            <th className="col-sm-2">PAKIET ROZSZERZONY</th>
            <th className="col-sm-2">
              PAKIET
              <br />
              VIP
            </th>
          </tr>

          <tr className="tableBackgroundRow">
            <th rowSpan={3}>
              Pakiet Indywidualny
              <small>
                <sup>1</sup>
              </small>
              <br />
              <small>(tylko pracownik)</small>
            </th>
            <th>Koszt pakietu</th>
            <th>44,00</th>
            <th>99,50</th>
            <th>399,00</th>
          </tr>

          <tr className="tableBackgroundRow">
            <th>
              <small>
                <i>Pracownik uprawniony do Pakietu Podstawowego</i>
              </small>
            </th>
            <th>
              <small>
                <i>Pakiet finansowany przez TVP</i>
              </small>
            </th>
            <th>
              <small>
                <i>Dopłata pracownika 55,50</i>
              </small>
            </th>
            <th>
              <small>
                <i>Dopłata pracownika 355,00</i>
              </small>
            </th>
          </tr>
          <tr className="tableBackgroundRow">
            <th>
              <small>
                <i>
                  Pracownik uprawniony do Pakietu Rozszerzonego<sup>2</sup>
                </i>
              </small>
            </th>
            <th>
              <small>-</small>
            </th>
            <th>
              <small>
                <i>0</i>
              </small>
            </th>
            <th>
              <small>
                <i>Dopłata pracownika 299,50</i>
              </small>
            </th>
          </tr>
          <tr>
            <th colSpan={2}>
              Pakiet Partnerski
              <small>
                <sup>3</sup>
              </small>
              <br />
              <small>
                (dla 1 członka rodziny tj. małżonka/partnera lub dziecka do 26
                r.ż.)
                <br />- finansowany przez Pracownika
              </small>
            </th>
            <th>65,00</th>
            <th>119,50</th>
            <th>399,00</th>
          </tr>
          <tr className="tableBackgroundRow">
            <th colSpan={2}>
              Pakiet Rodzinny
              <small>
                <sup>3</sup>
              </small>
              <br />
              <small>
                (dla wszystkich członków rodziny tj. małżonka/partnera oraz
                dziecka do 26 r.ż.)
                <br />- finansowany przez Pracownika
              </small>
            </th>
            <th>130,00</th>
            <th>239,00</th>
            <th>798,00</th>
          </tr>
          <tr>
            <th colSpan={5} className="text-center">
              Oddzielna deklaracja na każdego ubezpieczonego:
            </th>
          </tr>
          <tr>
            <th colSpan={2}>
              Pakiet Senior
              <small>
                <sup>4</sup>
              </small>
              <br />
            </th>
            <th>130,50</th>
            <th>310,90</th>
            <th>n/d</th>
          </tr>
          <tr className="tableBackgroundRow">
            <th colSpan={2}>
              Pakiet Dorosłe Dziecko
              <small>
                <sup>4</sup>
              </small>
              <br />
            </th>

            <th>118,60</th>
            <th>282,60</th>
            <th>n/d</th>
          </tr>
        </tbody>
      </table>
      <small>
        1. Koszt prywatnej opieki medycznej finansowanej przez pracodawcę
        stanowi podstawę wymiaru składek na ubezpieczenia społeczne oraz
        naliczania podatku dochodowego od osób fizycznych zgodnie z
        obowiązującymi przepisami prawa.
      </small>
      <br />
      <small>
        2. Zasady finansowania pakietu rozszerzonego - patrz poniżej.
      </small>
      <br />
      <small>
        3. Składka płatna dodatkowo, potrącana z wynagrodzenia, na podstawie
        pisemnej zgody pracownika.
      </small>
      <br />
      <small>
        4. Składka płatna dodatkowo - nie potrącana z wynagrodzenia. Wpłata na
        indywidualne konto, wskazane w deklaracji przystąpienia
        współubezpieczonego.
      </small>
      <br />

      <br />
      {/* <table className="table table-striped table-condensed secondColor">
        <tbody>
          <tr>
            <th className="text-center">Pakiet / zakres</th>
            <th className="text-center">Podstawowy</th>
            <th className="text-center">Rozszerzony</th>
            <th className="text-center">VIP</th>
          </tr>
          <tr>
            <th className="text-center">Indywidualny</th>
            <td className="text-center">44,00 zł</td>
            <td className="text-center">99,50 zł</td>
            <td className="text-center">399,00 zł</td>
          </tr>
          <tr>
            <th className="text-center">Partnerski*</th>
            <td className="text-center">65,00 zł</td>
            <td className="text-center">119,50 zł</td>
            <td className="text-center">399,00 zł</td>
          </tr>
          <tr>
            <th className="text-center">Rodzinny*</th>
            <td className="text-center">130,00 zł</td>
            <td className="text-center">239,00 zł</td>
            <td className="text-center">798,00 zł</td>
          </tr>
          <tr>
            <th className="text-center">Dorosłe Dziecko**</th>
            <td className="text-center">118,60 zł</td>
            <td className="text-center">282,60 zł</td>
            <td className="text-center">n/d</td>
          </tr>
          <tr>
            <th className="text-center">Senior**</th>
            <td className="text-center">130,50 zł</td>
            <td className="text-center">310,90 zł</td>
            <td className="text-center">n/d</td>
          </tr>
        </tbody>
      </table>
      <small>*składka płatna dodatkowo</small>
      <br />
      <small>
        **składka płatna dodatkowo na numer konta wskazany w deklaracji
        przystąpienia dla współubezpieczonego
      </small> */}
      <br />

      <b className="firstColor">
        Zasady finansowania pakietów przez Pracodawcę:
      </b>
      <br />
      <br />
      <ul className="secondColor">
        <li>
          <b>Pakiet podstawowy</b> - jest finansowany wszystkim Pracownikom
          Spółki
        </li>
        <li>
          <b>Pakiet rozszerzony</b> - jest finansowany uczestnikom Programu
          „Pełnosprawne Partnerstwo”, osobom 55+, dyrektorom i ich zastępcom
        </li>
        <li>
          <b> Pakiet VIP</b> - nie jest finansowany przez Pracodawcę
        </li>
      </ul>
    </React.Fragment>
  );
};

export default Ogolne;
