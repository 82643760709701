import { call } from 'redux-saga/effects';
import axios from 'axios';

import { SubmissionError } from 'redux-form';

export function* registerUserSaga(action) {
  const { values, resolve, reject } = action.payload;
  try {
    const response = yield call(registerUser, values);

    if (response.data.result.isSuccessful) {
      yield call(resolve, true);
    } else {
      yield call(
        reject,
        new SubmissionError(response.data.result.errorMessage)
      );
    }
  } catch (error) {
    console.log(error.response);
    if (error.response && error.response.data.result) {
      yield call(
        reject,
        new SubmissionError(error.response.data.result.errorMessage)
      );
    } else {
      yield call(
        reject,
        new SubmissionError({ _error: JSON.stringify(error.response.data) })
      );
    }
  }
}

const registerUser = (values) => {
  return axios.post('/api/domain/registerTuwpzuwUser', values);
};
