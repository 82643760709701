import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logout } from '../../../store/actions/index';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  close = () =>
    this.setState({
      isOpen: false
    });

  render() {
    const { hasPrograms, isAuthenticated, tuwpzuw } = this.props;

    let profileMenu = null;

    const generateMenu = this.props.menu.map((item) => {
      if (item && item.displayName === 'Profile') {
        profileMenu = item.nestedElements.map((nestedItem) => (
          <Link
            onClick={this.close}
            to={`/${nestedItem.pageId}`}
            key={nestedItem.nodeId}>
            <DropdownItem tag="div" className="userDropdownItem">
              <div className="userDropIcon">
                <FontAwesomeIcon
                  fixedWidth
                  icon={nestedItem.materialIcon || 'dot-circle'}
                />
              </div>
              <div className="userName">{nestedItem.displayName}</div>
            </DropdownItem>
          </Link>
        ));
        return null;
      }
      if (item.nestedElements) {
        return (
          <UncontrolledDropdown key={item.nodeId} nav inNavbar>
            <DropdownToggle nav caret>
              {item.displayName}
            </DropdownToggle>
            <DropdownMenu>
              {item.nestedElements.map((nestedItem) => (
                <Link
                  onClick={this.close}
                  to={`/${nestedItem.pageId}`}
                  key={nestedItem.nodeId}>
                  <DropdownItem>{nestedItem.displayName}</DropdownItem>
                </Link>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      } else if (item.pageId) {
        return (
          <NavItem key={item.nodeId}>
            <NavLink
              onClick={this.close}
              tag={RouterNavLink}
              style={{ color: '#003C7D', fontWeight: 'bolder' }}
              to={`/${item.pageId}`}>
              {item.displayName}
            </NavLink>
          </NavItem>
        );
      }
      return null;
    });
    // const generateMenu = null;

    return (
      <div
        className="fixedTop"
        style={{
          height: '80px',
          width: '100%'
          // backgroundColor: 'red'
          // marginBottom: '200px'
        }}>
        <Navbar
          dark
          expand="md"
          className="fixedWidth"
          style={{
            height: '80px',
            backgroundColor: '#f4f6f9'
            // paddingRight: '20px'
          }}>
          <NavbarBrand tag={Link} onClick={this.close} to="/">
            {(!isAuthenticated || tuwpzuw) && (
              <img
                style={{
                  height: '83px',
                  backgroundColor: '#w231232'
                }}
                src={
                  process.env.PUBLIC_URL + '/tuwpzuw/PZUW LOGO RGB_83x83.png'
                }
                alt="TUWPZUW logo"
              />
            )}
            {isAuthenticated && !tuwpzuw && (
              <img
                style={{
                  height: '50px',
                  borderRadius: '4px',
                  marginRight: '0.5rem'
                }}
                src="/public-assets/usp.png"
                alt="USP  logo"
              />
            )}
          </NavbarBrand>

          <NavbarToggler onClick={this.toggle} />

          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav navbar>
              {!isAuthenticated && (
                <React.Fragment>
                  <UncontrolledDropdown
                    nav
                    inNavbar
                    style={{ backgroundColor: 'w231232!important' }}>
                    <DropdownToggle
                      nav
                      carret
                      style={{
                        color: '#003C7D',
                        fontWeight: 'bolder',
                        backgroundColor: '#w231232!important'
                      }}>
                      Grupowe Ubezpieczenie TUW PZUW Opieka Medyczna (OM) -
                      Kontynuacja
                    </DropdownToggle>
                    {/* <DropdownMenu>
                      <Link onClick={this.close} to="/kontynuacja">
                        <DropdownItem>TVP Pracownicy</DropdownItem>
                      </Link> */}
                    {/*<Link onClick={this.close} to="/wspolpracownicy">
                        <DropdownItem>TVP Współpracownicy</DropdownItem>
                      </Link>
                      <Link onClick={this.close} to="/senior">
                        <DropdownItem>Senior</DropdownItem>
                      </Link>
                      <Link onClick={this.close} to="/dorosleDziecko">
                        <DropdownItem>Dorosle Dziecko</DropdownItem>
                      </Link> */}
                    {/* </DropdownMenu> */}
                  </UncontrolledDropdown>
                </React.Fragment>
              )}

              {/* {generateMenu} */}
              {this.props.isAuthenticated && this.props.tuwpzuw && (
                <UncontrolledDropdown
                  nav
                  inNavbar
                  style={{ backgroundColor: 'w231232!important' }}>
                  <DropdownToggle
                    nav
                    carret
                    style={{
                      color: '#003C7D',
                      fontWeight: 'bolder',
                      backgroundColor: '#w231232!important'
                    }}>
                    Grupowe Ubezpieczenie TUW PZUW Opieka Medyczna (OM) -
                    Kontynuacja
                  </DropdownToggle>
                </UncontrolledDropdown>
              )}
            </Nav>
            <Nav className="ml-auto" navbar>
              {this.props.isAuthenticated && (
                <React.Fragment>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      <FontAwesomeIcon
                        fixedWidth
                        icon="user-circle"
                        style={{ color: '#003C7D' }}
                      />
                      <span className="navbarUsername firstColor">
                        <b> {this.props.username}</b>
                      </span>
                    </DropdownToggle>
                    <DropdownMenu right className="userDropdown">
                      <div className="userDropdownItem profile">
                        <div className="userDropIcon">
                          <FontAwesomeIcon
                            fixedWidth
                            icon="user-circle"
                            // style={{ background: '#003C7D' }}
                          />
                        </div>
                        <div className="userName">{this.props.username}</div>
                      </div>
                      {hasPrograms && (
                        <Link onClick={this.close} to="/changeprogram/">
                          <DropdownItem tag="div" className="userDropdownItem">
                            <div className="userDropIcon">
                              <FontAwesomeIcon fixedWidth icon="exchange-alt" />
                            </div>
                            <div className="userName">Zmień program</div>
                          </DropdownItem>
                        </Link>
                      )}
                      {profileMenu}
                      <Link onClick={this.close} to="/change-password/">
                        <DropdownItem
                          tag="div"
                          onClick={() => {
                            this.close();
                          }}
                          className="userDropdownItem">
                          <div className="userDropIcon">
                            <FontAwesomeIcon fixedWidth icon="key" />
                          </div>
                          <div className="userName">Zmień hasło</div>
                        </DropdownItem>
                      </Link>
                      <DropdownItem
                        tag="div"
                        onClick={() => {
                          this.close();
                          this.props.logout();
                        }}
                        className="userDropdownItem">
                        <div className="userDropIcon">
                          <FontAwesomeIcon fixedWidth icon="sign-out-alt" />
                        </div>
                        <div className="userName">Wyloguj się</div>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </React.Fragment>
              )}
              {!this.props.isAuthenticated && (
                <React.Fragment>
                  <NavItem>
                    <NavLink
                      style={{ color: '#003C7D', fontWeight: 'bolder' }}
                      tag={RouterNavLink}
                      onClick={this.close}
                      to="/login/"
                      className="loginRegister">
                      <FontAwesomeIcon fixedWidth icon="sign-in-alt" />
                      Logowanie
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ color: '#003C7D', fontWeight: 'bolder' }}
                      tag={RouterNavLink}
                      onClick={this.close}
                      to="/register/"
                      className="loginRegister">
                      <FontAwesomeIcon fixedWidth icon="user-friends" />
                      Rejestracja
                    </NavLink>
                  </NavItem>
                </React.Fragment>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const programs = state.auth.programs || [];
  return {
    isAuthenticated: state.auth.token !== null,
    username: state.auth.user.name,
    tuwpzuw:
      state.auth.user.programId === 'b6f47e72-716e-4e3d-9ce1-797637bf3b69',
    hasPrograms: programs.length > 1 ? true : false,
    menu: state.menu.data && state.menu.data.menu ? state.menu.data.menu : []
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
