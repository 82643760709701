import React from 'react';
import { Row, Col, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from './Dashboard.module.scss';
import JumbotronMenuItem from '../../components/JumbotronMenuItem/JumbotronMenuItem';
import { connect } from 'react-redux';

const CardLink = (props) => {
  const { link, title, icon } = props;
  return (
    <Link to={link} className={classes.noDecoration}>
      <div className={classes.dashboardButton}>
        <div>
          <FontAwesomeIcon fixedWidth icon={icon} />
        </div>
        <div>{title}</div>
      </div>
    </Link>
  );
};

const Dashboard = (props) => (
  <React.Fragment>
    {props.isAuthenticated && props.tuwpzuw && (
      <Row>
        <Col xs={6} md={3}>
          <CardLink
            link="/01a82530-3c7f-46fa-a972-e418afb039da"
            title="Moje deklaracje"
            icon="file-invoice"
          />
        </Col>
        <Col xs={6} md={3}>
          <CardLink
            link="/kontynuacja/kup/"
            title="Wypełnij deklarację"
            icon="file-signature"
          />
        </Col>
        {/* <Col xs={6} md={3}>
          <CardLink
            link="/e512472b-b254-4127-9183-4ce840d76b71"
            title="Reklamacje i refundacje"
            icon="envelope"
          />
        </Col> */}
        {/* <Col xs={6} md={3}>
          <CardLink
            link="/77b0aff4-5429-4f4a-9ecb-c33faf739739"
            title="Moje dane"
            icon="user-edit"
          />
        </Col> */}
      </Row>
    )}
    <Row>
      <Col xs="12">
        <Alert
          color="warning"
          fade={false}
          style={{
            margin: '0rem 0rem 1rem 0rem',
            textAlign: 'justify'
          }}>
          <strong
            style={{
              fontWeight: '800'
            }}>
            Witamy!
          </strong>
          <br />
          <span
            style={{
              fontWeight: '600'
            }}>
            Jak umówić się na wizytę lekarską lub badanie?
            <br />
            Telefonicznie – 3 całodobowe infolinie czynne 7 dni w tygodniu:{' '}
            <strong>22 505 15 48; 799 698 698; 801 405 905</strong> (opłata
            zgodnie z taryfą operatora)
            <br /> Portal Pacjenta:{' '}
            <a href="https://moje.pzu.pl/cas/login">moje.pzu.pl</a>{' '}
            <small>
              (w przypadku problemów z założeniem konta prosimy dzwonić pod
              numer <b>22 566 50 07</b> - wybierz tonowo 5)
            </small>
            <br />
            Aplikacja mobilna: mojePZU mobile (do pobrania z App Store i Google
            Play)
          </span>
        </Alert>
      </Col>
    </Row>
    {/* <Row>
      <Col>
        <h3 style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          Ubezpieczenia zdrowotne:
        </h3>
      </Col>
    </Row> */}
    {/* <Row>
      <JumbotronMenuItem
        urlPrefix={'pracownicy'}
        insuranceName={'TVP Pracownicy'}
        img={'1'}
      />
      <JumbotronMenuItem
        urlPrefix={'wspolpracownicy'}
        insuranceName={'TVP Współpracownicy'}
        img={'2'}
      />
    </Row> */}
    {/* <Row>
      <Col>
        <h3 style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          Ubezpieczenia majątkowe:
        </h3>
      </Col>
    </Row> */}
    <Row>
      {/* <JumbotronMenuItem
        urlPrefix={'senior'}
        insuranceName={'Senior'}
        img={'8'}
        hasBorder={true}
      /> */}
      <JumbotronMenuItem
        urlPrefix={'kontynuacja'}
        insuranceName={'Kontynuacja'}
        img={'27'}
        // hasBorder={true}
      />
    </Row>
  </React.Fragment>
);

const mapStateToProps = (state) => {
  const programs = state.auth.programs || [];
  return {
    isAuthenticated: state.auth.token !== null,

    username: state.auth.user.name,
    tuwpzuw:
      state.auth.user.programId === 'b6f47e72-716e-4e3d-9ce1-797637bf3b69',
    hasPrograms: programs.length > 1 ? true : false,
    menu: state.menu.data && state.menu.data.menu ? state.menu.data.menu : []
  };
};

// const mapDispatchToProps = (dispatch) => {
//     return {
//         logout: () => dispatch(logout())
//     };
// }

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(Dashboard);
